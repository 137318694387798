import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchContext from "../Context/SearchContext";
import { Alert, Badge, Button, Dropdown, Modal, Spin, Tag } from "antd";
// import logo from "../images/fx-y.png";
import { LoadingOutlined } from "@ant-design/icons";

import logo from "../images/2.png";
import { MdOutlineKeyboardArrowDown, MdNotifications } from "react-icons/md";

import "./components.css";
import ProjectFeed from "./ProjectFeed";
import DashboardType from "./DashboardType";
import LiveTable from "../pages/dashboard/components/LiveTable";

export default function Header({ hideLeave }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, demo, count, setCount } = useContext(SearchContext);
  const [loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate("/dashboard/account");
          }}
        >
          Account
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            setLoading(true);
            if (demo.person) {
              updatePerson(
                { seat_status: "watcher", status: "left" },
                demo.person?._id
              );
            }
            logout();
          }}
        >
          Log Out
        </span>
      ),
    },
  ];
  const updatePerson = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/people/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const oldP = JSON.parse(window.localStorage.getItem("persons"));
          if (oldP) {
            let all = oldP.filter((f) => f._id !== id);
            window.localStorage.setItem("persons", JSON.stringify(all));
          }
          window.location.reload();
          // window.localStorage.removeItem("person")
          // logout();
        }
      });
  };
  const logout = () => {
    window.localStorage.removeItem("user");

    setUser();
    if (demo.person) {
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="header-container">
      <nav
        // style={{ background: isLoggedIn ? "#0d0d0d" : "black" }}
        className="navbar navbar-expand-lg navbar-light "
      >
        <div className="container-fluid my-container-flui">
          {/* Make the logo a link to the home page */}
          <span>
            <img className="logo-img-bc" src={logo} alt="" />
            {location.pathname === "/dashboard" ? <ProjectFeed /> : null}
          </span>
          <div style={{ paddingRight: "10px" }} className=" navbar-my-main">
            <span />
            {location.pathname === "/dashboard" ? (
              <>
                <DashboardType />
                <a target="blank" href={"https://guide.telemit.com/game-evaluation"}>
                  <Button className="grey-button">Guide </Button>
                </a>
                <LiveTable />
              </>
            ) : (
              <Link to={"/dashboard"}>
                {" "}
                <Button className="grey-button">Dashboard </Button>
              </Link>
            )}
            {demo.person &&
            location.pathname !== "/dashboard" &&
            hideLeave !== true ? (
              loading ? (
                <Spin
                  style={{ margin: "0px 25px 0px 30px" }}
                  indicator={antIcon}
                />
              ) : (
                <Button
                  onClick={() => {
                    setLoading(true);

                    updatePerson(
                      { seat_status: "watcher", status: "left" },
                      demo.person?._id
                    );
                  }}
                  style={{ marginLeft: "10px" }}
                  className="red-button"
                >
                  Leave{" "}
                </Button>
              )
            ) : null}
            {user?.id ? (
              <div className="header-right-c">
                <div className="roadm-t-div" style={{ marginLeft: "20px" }}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {user?.name} <MdOutlineKeyboardArrowDown />
                    </span>
                  </Dropdown>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    </div>
  );
}
