import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  Row,
  Spin,
  Tag,
  message,
  Tabs,
  Flex,
  Modal,
  Popconfirm,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowDown,
  FaArrowRight,
  FaEye,
  FaFacebook,
  FaGamepad,
  FaPlay,
  FaReddit,
  FaSteam,
  FaVideo,
  FaYoutube,
} from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import FullLoader from "../../../components/FullLoader";
import SearchContext from "../../../Context/SearchContext";

import { GiDragonOrb } from "react-icons/gi";
import { MdClose } from "react-icons/md";

export default function MarketingType() {
  const { user, gamesData, setGamesData, demo, setDemo, count, setCount } =
    useContext(SearchContext);

  const [loading, setLoadeing] = useState(true);

  //   const [mrData, setmrData] = useState();
  //   const [mrPageNo, setvmrPageNo] = useState(1);
  //   const [mrTotalP, setvmrTotalP] = useState();

  const [callApi, setCallApi] = useState(1);
  const [viewEye, setViewEye] = useState();

  useEffect(() => {
    console.log("called");
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getlist(u);
    }
  }, [demo.activeBlock, callApi, demo.mrPageNo, demo.callMarket]);

  const getlist = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${
        demo.mrPageNo
      }&limit=12&status=market&user_id=${u?.user_id}&version_id=${
        demo.activeBlock ? demo.activeBlock : ""
      }&channel=${demo.marketTypee ? demo.marketTypee : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];

          if (demo.mrPageNo > 1) {
            all = demo.mrData.concat(data.feedList);
          } else {
            all = [...data.feedList];
          }

          all = all?.map((obj) => ({ ...obj, key: obj._id }));
          setDemo({ ...demo, mrData: all, mrTotalP: data.totalPages });
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const antIcon2 = (
    <LoadingOutlined
      style={{
        fontSize: 10,
        color: "yellow",
      }}
      spin
    />
  );
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = demo.mrData.filter((p) => p._id !== row._id);

          setDemo({
            ...demo,
            mrData: all,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const vectorData = () => {
    return (
      <div>
        {demo.mrData && demo.mrData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Campaigns</p>
                <p className="txt-card-21">
                  There are currently no marketing campaigns for your games.
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="assets-bx-h1">
          {demo.mrData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {demo.mrData &&
              demo.mrData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <div className={`top-right-popup `}>
                        <Popconfirm
                          title="Delete this Task"
                          onConfirm={() => deleteTask(m)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="right"
                          trigger={"click"}
                        >
                          <b className="show-hover">
                            <MdClose
                              style={{
                                position: "relative",
                                zIndex: 120,
                              }}
                              className="circular-icon hover"
                            />
                          </b>
                        </Popconfirm>
                        {/* <AssetChat row={m} /> */}
                      </div>
                      <img src={m.thumbnail} alt="" />

                      <div
                        // style={{
                        //   display: "flexZ",
                        //   width: "100%",
                        //   justifyContent: "space-between",
                        // }}
                        className="public-top-c"
                      >
                        <b>{m.title}</b>
                        <b
                          onClick={() => {
                            setViewEye(m);
                          }}
                          className="icon-vd-mrc icon-eye-topc0s"
                        >
                          <FaEye />
                        </b>
                      </div>

                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                        className="public-top-c2"
                      >
                        <span>
                          {m.feed_type === "live" ? (
                            <Tag color="rgb(205, 1, 1)">Live</Tag>
                          ) : null}
                          {m.feed_type === "video" ? (
                            <b className="icon-vd-mrc">
                              <FaVideo />
                            </b>
                          ) : null}
                        </span>
                        <b className="icon-vd-mrc">
                          {m.channel === "reddit" ? <FaReddit /> : null}
                          {m.channel === "facebook" ? <FaFacebook /> : null}
                          {m.channel === "youtube" ? <FaYoutube /> : null}
                          {m.channel === "steam" ? <FaSteam /> : null}
                        </b>
                        <span>
                          {m.access === "live" ? (
                            <Tag color="rgb(205, 1, 1)">Live</Tag>
                          ) : null}
                          {m.access === "ready" ? (
                            <Tag color="rgb(1, 155, 4)">Ready</Tag>
                          ) : null}
                          {m.access === "planning" ? (
                            <Tag color="black"><Spin indicator={antIcon2} /> Planning</Tag>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          {demo.mrTotalP && demo.mrTotalP > demo.mrPageNo ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setDemo({ ...demo, mrPageNo: demo.mrPageNo + 1 });

                    setLoadeing(true);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
        <Modal
          // width={600}
          open={viewEye !== undefined}
          onCancel={() => {
            setViewEye();
          }}
          className="no-buttons-modal black-modal"
        >
          <h4 style={{ margin: "40px 0px" }}>
            No data for this campaign exists
          </h4>
        </Modal>
      </div>
    );
  };

  return vectorData();
}
