import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
  Spin,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import GreenTick from "../../components/GreenTick";
import ReactPlayer from "react-player";
import logo from "../../images/2.png";
import { LoadingOutlined } from "@ant-design/icons";

export default function Login({ afterSave, setIsSignup }) {
  const {
    setUser,
    user,
    loginChange,
    setLoginChange,
    resetAll,
    setOrganization,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [verifyD, setVerifyD] = useState(false);
  const [res, setRes] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [vError, setVError] = useState();
  const [u, setU] = useState();
  const [loadingS, setloadingS] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u && u.name) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setloadingS(true);
    addUserLogin(form);
  };

  const addUserLogin = (user) => {
    const pass = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    const obj = {
      password: pass,
      email: user.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/auth/login`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // if (response && response.isLogged && response.email_verified) {
        setLoading(false);
        if (response && response.isLogged) {
          window.localStorage.setItem("user", JSON.stringify(response));
          setForm({
            email: "",
            password: "",
          });

          // setFormType();
          // setLoading(false);
          setUser(response);
          resetAll();
          // setPActive();
          setLoginChange(!loginChange);
          getOrganization(response);

          if (afterSave) {
            afterSave(response);
          } else {
            navigate("/dashboard");
          }

          // } else if (!response.email_verified && response.isLogged) {
          //   setVerifyD(true);
          //   setU(response);
        } else {
          notification.error({
            message: "Error",
            description: response.msgError,
          });
        }
        setloadingS(false);
      });
  };

  const changeField = (key, val, row) => {
    // console.log(key, val, row);
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  const resentCode = () => {
    const obj = {
      email: form.email,
      verification_for: "new_registration",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/resend`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setRes(true);
        }
      });
  };

  const verifyEmail = () => {
    const obj = {
      code: Number(verifyCode),
      email: form.email,
      verification_for: "new_registration",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/verify`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let usr = u;
          usr.email_verified = true;
          window.localStorage.setItem("user", JSON.stringify(usr));
          setForm({
            email: "",
            password: "",
          });
          // setFormType();
          // setLoading(false);
          setUser(usr);
        } else {
          setVError(response.msgError);
        }
      });
  };
  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className={`${afterSave ? "" : "blurry-background"}`}>
      <div className="login-page">
        {/* <Modal
          onCancel={() => setFormType()}
          className="no-buttons-modal black-forget-modal "
          open={formType === "forget"}
        >
          <ForgetPassword />
        </Modal> */}

        <div className="sign-in-right-content">
          <div className="">
            <div className="logo-login-c">
              <img className="logo-img-bc" src={logo} alt="" />
            </div>
            <p className="sign-in-form-title">Login</p>
            {/* <div className="sign-in-form-text-block">
              Don't have an account?{" "}
              <span onClick={() => setIsSignup(true)} className=" sign-in-form-text-link hover" >
                <span>Sign up</span>
              </span>
            </div> */}
            {/* <div className="sign-in-form-tag">
              <div class="sign-in-form-tag-border"></div>

              <div class="sign-in-form-tag-text">Or continue with</div>
              <div class="sign-in-form-tag-border"></div>
            </div> */}
            {/* <p className="mb-4">Create a login split page using B.</p> */}
            <form className="fonr-lgn-c">
              <div className="form-group mb-3">
                <label for="inputEmail" class="sign-in-form-field-label">
                  Your Email *
                </label>
                <input
                  id="inputEmail"
                  type="email"
                  placeholder="Email address"
                  value={form.email}
                  required=""
                  autofocus=""
                  className="sign-in-form-input"
                  onChange={(e) =>
                    changeField("email", e.target.value.toLowerCase(), form)
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label for="inputPassword" class="sign-in-form-field-label">
                  Your Password *
                </label>
                <input
                  id="inputPassword"
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    changeField("password", e.target.value.trim(), form)
                  }
                  value={form.password}
                  required=""
                  className="sign-in-form-input"
                />
              </div>
              <div className="flex-end">
                {/* <Button type="link" onClick={() => navigate("/signup")}>
                  Create Account
                </Button> */}

                {loading ? (
                  <Spin indicator={antIcon} size="large" />
                ) : (
                  <input
                    onClick={(e) => {
                      setLoading(true);
                      handleSubmit(e);
                    }}
                    type="submit"
                    data-wait="Please wait..."
                    class={`sign-in-submit-button w-button ${
                      afterSave ? "" : " adjust-color--l"
                    }`}
                    value="Log In"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        width={400}
        className="no-h-bo-b-modal no-close-model"
        open={verifyD}
      >
        <div className="verifybtn-c">
          <h5> Enter Verify Code</h5>
          <p>
            Please check your email <b>{form.email}</b> for verification code
          </p>
          <input
            onChange={(e) => setVerifyCode(e.target.value)}
            className={`form-control ${vError ? " error-input" : ""} `}
            type="number"
            value={verifyCode}
            placeholder="Verify code"
          />
          {vError ? <i>{vError}</i> : null}
          <div className="code-veri-lg">
            {res ? (
              <div>
                <GreenTick width="30px" height="30px" />
              </div>
            ) : (
              <Button onClick={resentCode} type="link">
                Resend Code
              </Button>
            )}
            <Button onClick={verifyEmail}>Verify</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
