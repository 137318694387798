import React, { useState, useEffect } from "react";

function CountdownTimer({ targetDate }) {
  const [countDown, setCountDown] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown(calculateTimeLeft(targetDate));
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [targetDate]); // Dependency array to ensure recalculation on targetDate change

  if (countDown <= 0) {
    return <div>Time's up!</div>; // Display message when countdown reaches zero
  }

  const { days, hours, minutes, seconds } = formatTime(countDown);

  return (
    <div className="countdown-css-c">
      {days > 0 ? (
        <span>{days} days</span>
      ) : (
        <span>
          {hours.toString().padStart(2, "0")}:
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </span>
      )}
    </div>
  );
}

function calculateTimeLeft(targetDate) {
  const now = new Date();
  const target = new Date(targetDate);
  const difference = target - now;

  // Handle potential negative values for expired dates
  if (difference <= 0) {
    return 0;
  }

  return difference;
}

function formatTime(ms) {
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
}

export default CountdownTimer;
