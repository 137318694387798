import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { FaInfo, FaMinus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Button } from "antd";

export default function FeedPopup({
  active,
  setActive,
  setActiveRow,
  activeRow,
}) {
  const playerRef = useRef(null);
  const [adjusted, setAdjusted] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const adjustPlay = (a) => {
    if (playerRef.current && !adjusted) {
      setAdjusted(true);
      playerRef.current.seekTo(
        activeRow?.timestamp ? activeRow.timestamp : 0,
        "seconds"
      );
    }
  };
  const videoPlayer = () => {
    return (
      <ReactPlayer
        onReady={() => {
          adjustPlay();
        }}
        ref={playerRef}
        playing
        url={active?.file_url}
        className=""
        controls
      />
    );
  };
  return active ? (
    <div className={`unique-popup ${isModal ? " bigger-popup-c" : ""}`}>
      <div className="close-iconscs">
        <MdClose
          onClick={() => {
            setActive();
            setActiveRow();
          }}
        />
      </div>
      <h4 className="title-feed-pop">{active.title}</h4>
      <div className="video-container radius-video">{videoPlayer()}</div>
      <div className="flex-end">
        <Button
          className="grey-button"
          onClick={() => {
            setIsModal(!isModal);
          }}
        >
          {isModal ? "Close" : "Expand"}
        </Button>
      </div>
    </div>
  ) : null;
}
