import { Button, Checkbox, Input, Modal, Select, Spin, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaMagic } from "react-icons/fa";
import SearchContext from "../../../../../Context/SearchContext";
import axios from "axios";
import { BiTime } from "react-icons/bi";
const { Option } = Select; // Destructure Option from Select

export default function UpScale({ m }) {
  const { images, setImages, user, tasks } = useContext(SearchContext);

  const [show, setShow] = useState();
  const [selected, setSelected] = useState("Upscale");
  const [scalT, setScalT] = useState("2k");
  const [frameRate, setframeRate] = useState(30);
  const [foreg, setforeg] = useState("green-screen");
  const [block_id, setblock_id] = useState();
  const [audio_id, setaudio_id] = useState();
  const [blocks, setBlocks] = useState();
  const [voices, setVoices] = useState();
  const [enh, setEnh] = useState();
  const [created, setCreated] = useState(false);
  const [text, setText] = useState("");
  const [duration, setDuration] = useState(1); // State to store duration input

  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([
    "Upscale",
    "FPS",
    "Foreground",
    "Face Swap",
    "Talk",
    "Enhance",
    "Reimagine",
  ]);

  useEffect(() => {
    if (selected === "Face Swap" && blocks === undefined) {
      getBlocksGenerated();
    }
  }, [selected]);

  const getBlocksGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/block/?page=1&limit=30&sort_by=${-1}&user_id=${
          user && user.user_id
        }&block_type=character`
      );
      let all = [];
      if (response.data?.blockList) {
        for (let i = 0; i < response.data?.blockList.length; i++) {
          all.push({
            value: response.data?.blockList[i]._id,
            label: response.data?.blockList[i].block_name,
          });
        }
      }

      setBlocks(all);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const upscale = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      setting: scalT,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/upscale`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const fps = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      setting: frameRate,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/fps`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const foreground = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      setting: foreg,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/foreground`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const enhance = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      setting: enh === "Query" ? text : enh,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/enhance`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const faceswap = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      audio_id,
      block_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/faceswap`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };

  const talk = () => {
    const obj = {
      video_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
      audio_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/video/talk`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };

  const getVideos = (v) => {
    if (v.length === 0) {
      return;
    }
    const obj = {
      query: v,
      user_id: user && user.user_id,
      media_type: "voice",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search/user`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          let all = [];

          for (let i = 0; i < data.length; i++) {
            all.push({
              value: data[i]._id,
              label: data[i].query,
            });
          }

          setVoices(all);
        }
      });
  };

  const reimagine = async () => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/remix",
        {
          query: text,
          user_id: user && user.user_id,
          duration: duration,
          // voice,
          image_id: m._id,
        }
      );
      if (response.isSuccess) {
        setCreated(true);
      }
      setLoading(false);
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <Tooltip title="Info">
        <b onClick={() => setShow(m)} className="show-hover">
          <FaMagic />
        </b>
      </Tooltip>
      <Modal
        open={show !== undefined}
        onCancel={() => setShow()}
        className="no-buttons-modal black-modal"
        width={700}
      >
        <div className="sel-css-btns">
          {types.map((t, ti) => {
            return (
              <Button
                onClick={() => {
                  setSelected(t);
                  setCreated(false);
                }}
                key={ti}
                className={`editor-tag-tab ${
                  selected === t ? " active-tg-wdt" : ""
                }`}
                size="small"
              >
                {t}
              </Button>
            );
          })}
        </div>
        <div>
          {selected === "Upscale" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Resolution</h5>
                <Checkbox
                  style={{ margin: "10px 5px 0px 15px" }}
                  checked={scalT === "2k"}
                  onClick={() => setScalT("2k")}
                >
                  2k
                </Checkbox>
                <Checkbox
                  checked={scalT === "4k"}
                  onClick={() => setScalT("4k")}
                >
                  4k
                </Checkbox>
              </span>
              <div className="space-between">
               
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      upscale();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "FPS" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Frame Rate</h5>
                {[24, 30, 48, 60].map((fr, fri) => {
                  return (
                    <Checkbox
                      style={{ margin: "10px 5px 0px 15px" }}
                      key={fri}
                      checked={frameRate === fr}
                      onClick={() => setframeRate(fr)}
                    >
                      {fr}
                    </Checkbox>
                  );
                })}
              </span>
              <div className="space-between">
              
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}

                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      fps();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Foreground" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Foreground</h5>

                <Checkbox
                  style={{ margin: "10px 5px 0px 15px" }}
                  checked={foreg === "green-screen"}
                  onClick={() => setforeg("green-screen")}
                >
                  Green Screen
                </Checkbox>
                <Checkbox
                  style={{ margin: "10px 5px 0px 10px" }}
                  checked={foreg === "alpha-mask"}
                  onClick={() => setforeg("alpha-mask")}
                >
                  Alpha Mask
                </Checkbox>
                <Checkbox
                  style={{ margin: "10px 5px 0px 10px" }}
                  checked={foreg === "foreground-mask"}
                  onClick={() => setforeg("foreground-mask")}
                >
                  Foreground Mask
                </Checkbox>
              </span>
              <div className="space-between">
               
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      foreground();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {selected === "Enhance" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Enhance</h5>

                <Checkbox
                  style={{ margin: "10px 5px 0px 15px" }}
                  checked={enh === "Face"}
                  onClick={() => setEnh("Face")}
                >
                  Face
                </Checkbox>
                <Checkbox
                  style={{ margin: "10px 5px 0px 10px" }}
                  checked={enh === "Body"}
                  onClick={() => setEnh("Body")}
                >
                  Body
                </Checkbox>
                <Checkbox
                  style={{ margin: "10px 5px 0px 10px" }}
                  checked={enh === "Query"}
                  onClick={() => setEnh("Query")}
                >
                  Query
                </Checkbox>
              </span>
              {enh === "Query" ? (
                <table style={{ marginTop: "10px" }} className="padding-table">
                  <tr>
                    <td>
                      {" "}
                      <b>Query: </b>
                    </td>
                    <td>
                      <Input
                        className="grey-input"
                        placeholder="Query"
                        value={text}
                        style={{ width: 250 }}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </td>
                  </tr>
                </table>
              ) : null}
              <div className="space-between">
               
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      enhance();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {selected === "Face Swap" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Face Swap</h5>
                <table className="padding-table">
                  <tr>
                    <td>
                      {" "}
                      <b>Character: </b>
                    </td>
                    <td>
                      {" "}
                      {blocks ? (
                        <Select
                          showSearch
                          placeholder="Select a Character"
                          optionFilterProp="children"
                          className="grey-input"
                          style={{ width: "200px" }}
                          onChange={(v) => setblock_id(v)}
                          filterOption={filterOption}
                          options={blocks}
                        />
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <b>Voice: </b>
                    </td>
                    <td>
                      {" "}
                      <Select
                        showSearch
                        placeholder="Select a Voice"
                        optionFilterProp="children"
                        className="grey-input"
                        style={{ width: "200px" }}
                        onChange={(v) => setaudio_id(v)}
                        onSearch={(v) => getVideos(v)}
                        filterOption={filterOption}
                        options={voices ? voices : []}
                      />
                    </td>
                  </tr>
                </table>
              </span>
              <div className="space-between">
               
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      faceswap();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {selected === "Reimagine" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>New Text</h5>
                <table className="padding-table">
                  <tr>
                    <td>
                      {" "}
                      <b>Text: </b>
                    </td>
                    <td>
                      <Input
                        className="grey-input"
                        placeholder="Reimagine"
                        value={text}
                        style={{ width: 250 }}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <b>Duration: </b>
                    </td>
                    <td>
                      <Select
                        placeholder="Time"
                        style={{ width: 120 }} // Set the desired width in pixels
                        value={duration}
                        onChange={(value) => setDuration(value)}
                        className="grey-input"
                      >
                        <Option value={1}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          3s
                        </Option>
                        <Option value={2}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          6s
                        </Option>
                        <Option value={4}>
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          12s
                        </Option>
                        <Option value={6}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          18s
                        </Option>
                        <Option value={8}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          24s
                        </Option>
                        <Option value={10}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          30s
                        </Option>
                        <Option value={12}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          36s
                        </Option>
                        <Option value={14}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          42s
                        </Option>
                        <Option value={16}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          48s
                        </Option>
                        <Option value={18}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          54s
                        </Option>
                        <Option value={20}>
                          {" "}
                          <BiTime
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          1m
                        </Option>
                      </Select>
                    </td>
                  </tr>
                </table>
              </span>
              <div className="space-between">
               
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      reimagine();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {selected === "Talk" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <span>
                <h5>Talk</h5>
                <table className="padding-table">
                  <tr>
                    <td>
                      {" "}
                      <b>Voice: </b>
                    </td>
                    <td>
                      {" "}
                      <Select
                        showSearch
                        placeholder="Select a Voice"
                        optionFilterProp="children"
                        className="grey-input"
                        style={{ width: "200px" }}
                        onChange={(v) => setaudio_id(v)}
                        onSearch={(v) => getVideos(v)}
                        filterOption={filterOption}
                        options={voices ? voices : []}
                      />
                    </td>
                  </tr>
                </table>
              </span>
              <div className="space-between">
                
                {created ? (
                  <b className="alert-created">Your video is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      talk();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
