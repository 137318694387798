import {
  message,
  Tabs,
  Tooltip,
  Upload,
  Button,
  Typography,
  Tag,
  Input,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import { FaQuestionCircle } from "react-icons/fa";
import SearchContext from "../../../Context/SearchContext";
const { Paragraph } = Typography;
export default function MyOrganization() {
  const { organization, setOrganization } = useContext(SearchContext);
  const [save, setSave] = useState();
  const [userName, setuserName] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [UploadedFile2, setUplaoded2] = useState([]);
  const [showApiKey, setShowApiKey] = useState(false);

  useEffect(() => {
    if (organization === undefined) {
      getOrganization();
    }
  }, []);

  const getOrganization = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organization);
        }
      });
  };

  const copyApiKeyToClipboard = () => {
    // Copy the API key to the clipboard
    const textField = document.createElement("textarea");
    textField.innerText = organization.api_key;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const updateOrganization = () => {
    setSave(false);
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("business_name", organization.business_name);
    formData.append("color_code", organization.color_code);
    if (UploadedFile.length) {
      formData.append("image", UploadedFile[0].originFileObj);
    }
    if (UploadedFile2.length) {
      formData.append("fonts", UploadedFile2[0].originFileObj);
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setUplaoded([]);
        setUplaoded2([]);
        if (response && response.isSuccess) {
          message.success("Updated");
          setOrganization(response.organizationData);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const props = {
    name: "file",
    status: "done",
    multiple: false,
    listType: "picture-card",

    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    fileList: UploadedFile,

    onChange(info) {
      setSave(true);
      console.log(UploadedFile);
      if (info.fileList.length) {
        setUplaoded([...[], info.file]);
      } else {
        setUplaoded([]);
      }
    },
  };

  const props2 = {
    name: "file",
    status: "done",
    multiple: false,

    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    fileList: UploadedFile2,

    onChange(info) {
      setSave(true);
      console.log(UploadedFile2);
      if (info.fileList.length) {
        setUplaoded2([...[], info.file]);
      } else {
        setUplaoded2([]);
      }
    },
  };
  return (
    <div className="container">
      <div className="acc-topc form-pf">
        <div className=" account-page-c">
          {organization ? (
            <div className="tags-date-area right-area-det-m">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label for="name" class="label-pf">
                        <span class="title-pf">Name</span>
                        <Input
                          class="input-field-pf"
                          type="text"
                          placeholder="User Name"
                          value={organization.business_name}
                          onChange={(e) => {
                            setSave(true);
                            setOrganization({
                              ...organization,
                              business_name: e.target.value,
                            });
                          }}
                          style={{ width: "300px" }}
                        />
                      </label>
                    </td>
                  </tr>
                  {/* <tr>
                  <td>
                    <h6>Color:</h6>
                  </td>
                  <td>
                    <input
                      type="color"
                      onChange={(e) => {
                        setSave(true);
                        setOrganization({
                          ...organization,
                          color_code: e.target.value,
                        });
                      }}
                      value={organization.color_code}
                    />
                  </td>
                </tr> */}

                  <tr>
                    <td style={{ display: "flex" }}>
                      <label for="root-email" class="label-pf">
                        <span class="title-pf">Root Email</span>
                        <div className="">
                          <Input
                            disabled
                            type="text"
                            value={organization.root_email}
                            style={{ width: "300px" }}
                            class="input-field-pf"
                          />{" "}
                          <Tooltip
                            color={
                              organization?.color_code &&
                              organization.color_code !== "null"
                                ? organization.color_code
                                : "black"
                            }
                            placement="right"
                            title="To change your root email, please contact us."
                          >
                            <FaQuestionCircle
                              style={{
                                marginLeft: "5px",
                                marginTop: "-30px",
                              }}
                              className="aut-q-lf "
                            />
                          </Tooltip>
                        </div>
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {showApiKey ? (
                        <label for="api_key" class="label-pf">
                          <span class="title-pf">Api Key</span>
                          <Input
                            disabled
                            class="input-field-pf"
                            type="text"
                            value={organization.api_key}
                            style={{ width: "300px" }}
                          />
                        </label>
                      ) : (
                        <label for="api_key" class="label-pf">
                          <span class="title-pf">Api Key</span>
                          <Input
                            disabled
                            class="input-field-pf"
                            type="password"
                            value="************" // Placeholder text or masked value
                            style={{ width: "300px" }}
                          />
                        </label>
                      )}
                      {showApiKey && (
                        <Button type="primary" onClick={copyApiKeyToClipboard}>
                          Copy
                        </Button>
                      )}
                      <br />
                      <div className="shiw-api-k">
                        <Tag
                          className={``}
                          color="black"
                          onClick={() => setShowApiKey(!showApiKey)}
                        >
                          {showApiKey ? "Hide" : "Show"} API Key
                        </Tag>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        {organization.subscription === "Community" && (
                          <Tag color="green">Community</Tag>
                        )}
                      </h6>

                      <h6>
                        {organization.subscription === "Pro" && (
                          <Tag color="green">Pro</Tag>
                        )}
                      </h6>
                      <h6>
                        {organization.subscription === "Teams" && (
                          <Tag color="green">Teams</Tag>
                        )}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ paddingTop: "20px" }} className="flex-end">
                      {save ? (
                        <div
                          onClick={updateOrganization}
                          className="hover-card-b"
                        >
                          Update
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
              {organization && organization.subscription === "free" ? (
                <b style={{ marginLeft: "10px" }}>
                  You currently have up to 20 minutes of free usage to try out
                  the platform
                </b>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
