import {
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Spin,
  Tooltip,
  message,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaMagic } from "react-icons/fa";
import SearchContext from "../../../../../Context/SearchContext";
import axios from "axios";
import { BiTime } from "react-icons/bi";
const { Option } = Select; // Destructure Option from Select

export default function UpScaleImage({ m }) {
  const { images, setImages, user, tasks } = useContext(SearchContext);

  const [show, setShow] = useState();
  const [selected, setSelected] = useState("Upscale");

  const [created, setCreated] = useState(false);
  const [text, setText] = useState("");

  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([
    "Upscale",
    "Background remove",

    "Advertising",
    "Restore",
    "Animate",
    "Angles",
  ]);

  const restore = () => {
    const obj = {
      image_id: m._id,
      related_data: m._id,
      user_id: m.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/restore`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const advertising = () => {
    const obj = {
      image_id: m._id,
      query: text,
      user_id: m.user_id,
      related_data: m._id,

    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/ad`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };

  const angels = () => {
    const obj = {
      image_id: m._id,
      related_data: m._id,

      user_id: m.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/angles`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };

  const upscale = () => {
    const obj = {
      image_id: m._id,
      related_data: m._id,

      user_id: m.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/upscale`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };
  const bgRemove = () => {
    const obj = {
      image_id: m._id,
      related_data: m._id,

      user_id: m.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(
      `https://embed.axv.ai/api/create/image/remove_background`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setCreated(true);
        }
        setLoading(false);
      });
  };

  const animate = async (srch, isThunder) => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        {
          query: text,
          user_id: user && user.user_id,
          stream_id: srch && srch._id,
          stream: srch ? "init" : undefined,
          duration: 1,
          related_data: m._id,

          username: user && user.username,
          profile_picture: user && user.profile_picture,

          image_id: m._id,
        }
      );
      console.log(response)
      if (response.data.isSuccess) {
        setCreated(true);
      }
      setLoading(false)
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  return (
    <>
      <Tooltip title="Info">
        <b onClick={() => setShow(m)} className="show-hover">
          <FaMagic />
        </b>
      </Tooltip>
      <Modal
        open={show !== undefined}
        onCancel={() => setShow()}
        className="no-buttons-modal black-modal"
        width={700}
      >
        <div className="sel-css-btns">
          {types.map((t, ti) => {
            return (
              <Button
                onClick={() => {
                  setSelected(t);
                  setCreated(false);
                }}
                key={ti}
                className={`editor-tag-tab ${
                  selected === t ? " active-tg-wdt" : ""
                }`}
                size="small"
              >
                {t}
              </Button>
            );
          })}
        </div>
        <div>
          {selected === "Restore" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>

              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      restore();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Background remove" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>

              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      bgRemove();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Animate" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>
              <table style={{ marginTop: "10px" }} className="padding-table">
                <tr>
                  <td>
                    {" "}
                    <b>Query: </b>
                  </td>
                  <td>
                    <Input
                      className="grey-input"
                      placeholder="Query"
                      value={text}
                      style={{ width: 250 }}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </td>
                </tr>
              </table>
              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      animate();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Upscale" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>

              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      upscale();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Angles" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>

              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      angels();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {selected === "Advertising" ? (
            <div className="up-sel-b-a">
              <p className="detail-text-sc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
                quo rem nesciunt officiis sed quia, sequi repellat. Repellendus
                similique assumenda dolor expedita distinctio ex adipisci nihil,
                sed necessitatibus rem praesentium?
              </p>

              <table style={{ marginTop: "10px" }} className="padding-table">
                <tr>
                  <td>
                    {" "}
                    <b>Query: </b>
                  </td>
                  <td>
                    <Input
                      className="grey-input"
                      placeholder="Query"
                      value={text}
                      style={{ width: 250 }}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </td>
                </tr>
              </table>
              <div className="space-between">
                {created ? (
                  <b className="alert-created">Your image is being created</b>
                ) : (
                  <span />
                )}
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button
                    class="button-36"
                    onClick={() => {
                      advertising();
                      setLoading(true);
                    }}
                  >
                    {" "}
                    Create
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
