import React, { useContext, useEffect, useState } from "react";
import "./BackgroundVideo.css";

import { motion, AnimatePresence } from "framer-motion";

import { FaArrowRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

import img1 from "./component/images/1.png";
import img3 from "./component/images/3.png";
import imgc from "./component/images/meme.gif";
import mashroomGif from "./component/images/meme (1).gif";

import carGif from "./component/images/yellow_car.gif";
import keyGif from "./component/images/Key.gif";
import potionGif from "./component/images/Potion.gif";
import treasureGif from "./component/images/Treasure0.gif";
// import imgc from "./component/images/d8d1fe38-88d6-4de2-9afb-c6e2ac777c71_transparent_null_400.webp";
import SearchContext from "../../Context/SearchContext";
import LandingHeader from "./component/LandingHeader";

function LandingPage() {
  const { user } = useContext(SearchContext);

  const isMobile = useMediaQuery({ maxWidth: 680 });

  const [visibleImage, setVisibleImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleImage((prevImage) => (prevImage === 3 ? 1 : prevImage + 1));
    }, 8500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Store the original background image
    const originalBackgroundImage = document.body.style.backgroundImage;

    // Set the background image to none
    document.body.style.backgroundImage = "none";

    // Cleanup function to restore the original background image
    return () => {
      document.body.style.backgroundImage = originalBackgroundImage;
    };
  }, []);

  return (
    <div
      style={{
        minHeight: "100px",
        height: "100vh",
      }}
      class="blurry-background new-landing-c"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2 }}
      >
        <img className="img-1-ch hide-mobile" src={img1} alt="" />
      </motion.div>
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2 }}
      >
        <img className="img-13-ch hide-mobile" src={img3} alt="" />
      </motion.div> */}
      <div
        style={{ minHeight: "100px", height: "100vh" }}
        className="control-bf-left"
      >
        <LandingHeader />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="landing-info-t"
        >
          <div className="text-area-landing-ctb">
            <h1>
              Unlimited <span className="text-coloredc-"> Playtesting </span> {" "}
              <br />
              On <span className="text-coloredc-">Demand</span> 
            </h1>
            <div className="text-sixe0card">
            Transform your game development with Playtest X. Our platform dramatically reduces feedback cycles, transforming months of testing into mere hours. Gain invaluable player insights and iterate faster than ever before.
            </div>
          </div>
          <div className="btm-btns-tb-">
            <div className="image-main-card-lnd">
              {/* <img
                src={pongGif}
                alt=""
                className={`mian-cntr-img ${
                  visibleImage === 1 ? "fade-in" : "fade-out"
                }`}
              /> */}
              <img
                src={imgc}
                alt=""
                className={`mian-cntr-img snd-img-lnd ${
                  visibleImage === 1 ? "fade-in" : "fade-out"
                }`}
              />

              <img
                src={carGif}
                alt=""
                className={`mian-cntr-img  ${
                  visibleImage === 2 ? "fade-in" : "fade-out"
                }`}
              />

              <img
                src={mashroomGif}
                alt=""
                className={`mian-cntr-img snd-img-lnd ${
                  visibleImage === 3 ? "fade-in" : "fade-out"
                }`}
              />
            </div>
            <div class="tele-cards landing-tele-card">
              <a target="blank" href="https://guide.telemit.com/snapshots">
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img
                      style={{ width: "23px" }}
                      src={potionGif}
                      alt=""
                      className="card-giv-ce"
                    />
                    Develop
                  </p>
                  <p className="tele-c-desc">
                    Make better decisions by getting a video and visual snapshot
                    of every build and version of your game.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
              <a
                target="blank"
                href="https://guide.telemit.com/game-evaluation"
              >
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img src={keyGif} alt="" className="card-giv-ce" />
                    Playtest
                  </p>
                  <p className="tele-c-desc">
                    Cut your feedback loop to hours with Telemit, helping you
                    build a game players love.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
              <a target="blank" href="https://guide.telemit.com/stream">
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img src={treasureGif} alt="" className="card-giv-ce" />
                    Market
                  </p>
                  <p className="tele-c-desc">
                    Engage more channels and build your community with a
                    continuous livestream of your game.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
              {/* <a
                  target="blank"
                  href="https://guide.telemit.com/snapshots"
                >
                  <div class="tele-card tele-blue heigth-tele-card">
                    <p class="tele-tip">
                      <img
                        src={thunderGif}
                        alt=""
                        style={{ width: "25px" }}
                        className="card-giv-ce"
                      />
                      Automate
                    </p>
                    <p className="tele-c-desc">
                    Reduce the complexity of building games with tools that simplify your workflow.
                    </p>
                    <div className="arrow-c-mcs">
                      <FaArrowRight />
                    </div>
                  </div>
                </a> */}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default LandingPage;
