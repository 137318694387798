import { Input, Layout, Tooltip } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../Context/SearchContext";
import account from "../../../images/account.png";

const { Header, Sider, Content } = Layout;
export default function AccountsHeader({ style }) {
  const { images, setImages, user, tasks, setTasks } =
    useContext(SearchContext);

  return (
    <div  className="header-cards-btn">
      <div className="help-text-con"></div>

      <div className="filter-box-r">
        <div
          className={`hover-card-b ${
            tasks.disAccount === undefined || tasks.disAccount === "account"
              ? " selected-crd"
              : ""
          } `}
          onClick={() => {
            setTasks({
              ...tasks,
              disAccount: "account",
            });
          }}
        >
          Account
        </div>
        <div
          className={`hover-card-b ${
            tasks.disAccount === "Organization" ? " selected-crd" : ""
          } `}
          onClick={() => {
            setTasks({
              ...tasks,
              disAccount: "Organization",
            });
          }}
        >
          Organization
        </div>
        <div
          className={`hover-card-b ${
            tasks.disAccount === "Team" ? " selected-crd" : ""
          } `}
          onClick={() => {
            setTasks({
              ...tasks,
              disAccount: "Team",
            });
          }}
        >
          Team
        </div>
        <div
          className={`hover-card-b ${
            tasks.disAccount === "Billing" ? " selected-crd" : ""
          } `}
          onClick={() => {
            setTasks({
              ...tasks,
              disAccount: "Billing",
            });
          }}
        >
          Billing
        </div>
      </div>
      <div className="help-text-con"></div>
    </div>
  );
}
