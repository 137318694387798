import React, { useContext, useEffect } from "react";
import SearchContext from "../Context/SearchContext";
import { Select } from "antd";

import FeedPlusModal from "./FeedPlusModal";
const { Option } = Select;

export default function ProjectFeed() {
  const {
    demo,
    setDemo,
    organization,
    setGamesData,
    gamesData,
    plusFeed,
    setPlusFeed,
  } = useContext(SearchContext);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (
      (demo.data === undefined || demo.callFeedGame || organization.success) &&
      u
    ) {
      getrolePlay();
    }
  }, [demo.callFeedGame, organization?.success]);

  const getrolePlay = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=1&limit=10&sort_by=${-1}&feed_type=game&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.feedList && data.feedList.length) {
          setDemo({ ...demo, data: data.feedList, activeP: data.feedList[0] });
        } else if (data && data.feedList && data.feedList.length === 0) {
          console.log(data.feedList, "data.feedList");
          setPlusFeed({
            ...plusFeed,
            creeate: true,
            time_length: 1,
            name: "",

            email: "",
            activeCard: undefined,
            isFirst: true,
          });
        }
      });
  };
  const adjustPro = (e) => {
    let data = demo.data.filter((f) => f._id === e);
    setDemo({ ...demo, activeP: data.length ? data[0] : undefined });
  };
  return (
    <>
      {demo.data ? (
        <Select
          value={demo.activeP?.title}
          onChange={(e) => {
            adjustPro(e);
          }}
          className="transparentinput"
          style={{ marginLeft: "20px" }}
        >
          {demo.data.map((d, di) => {
            return (
              <Option key={di} value={d._id}>
                {d.title}
              </Option>
            );
          })}
        </Select>
      ) : null}
      <FeedPlusModal />
    </>
  );
}
