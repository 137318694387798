import { Col, InputNumber, Row, Slider } from "antd";
import React, { useContext, useState } from "react";
import "./billing.css";
import Header from "../../components/Header";
import { TbArrowRight } from "react-icons/tb";
import SearchContext from "../../Context/SearchContext";
export default function Billing() {
  const { organization } = useContext(SearchContext);

  const [inputValue, setInputValue] = useState(1);
  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  return (
    <div className="container">
      <div className="form-pf">
        <div className="billing-page-main-cb ">
          <div class="flex-ceenter">
            <button style={{marginBottom:"10px"}} className={`my-lbutton fle-x-c  green-my-lb `}>
              Balance: {organization?.balance}
            </button>
          </div>
          <Row>
            <Col span={24} style={{ padding: "10px" }}>
              <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Standard</h4>
                        <p>
                          Telemit prices for the amount of hours of feedback and
                          marketing usage you request. Pay for the hours you
                          only use.
                        </p>
                      </div>
                      <div>
                        <a
                          href={organization?.usage_link}
                          target="blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div className={`my-lbutton black-my-button`}>
                            Purchase
                            <TbArrowRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>${organization?.price}/min</h5>{" "}
                      <p>each minute Telemit is used.</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
