import { Button, Col, Row, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowRight,
  FaDiscord,
  FaFacebook,
  FaInfoCircle,
  FaInstagram,
  FaMinus,
  FaPause,
  FaPlay,
  FaShareAlt,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineWeb } from "react-icons/md";
import { AiFillTwitterCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import dummyVideo from "../../../images/home-video.mp4";

import ReactPlayer from "react-player";
import Typewriter from "typewriter-effect";

import AddPerson from "../components/AddPerson";
import SearchContext from "../../../Context/SearchContext";
import Paragraph from "antd/es/typography/Paragraph";

const icons = {
  facebook: <FaFacebook />,
  discord: <FaDiscord />,
  instagram: <FaInstagram />,
  youtube: <FaYoutube />,
  tiktok: <FaTiktok />,
  website: <MdOutlineWeb />,
  X: <AiFillTwitterCircle />,
};

export default function PreeLoad({ row }) {
  const { demo, setDemo } = useContext(SearchContext);
  const [play, setPlay] = useState(true);

  const handleCopy = () => {
    // Copy the URL to the clipboard
    const url = `https://telemit.com/${row._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Show success message
        message.success("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        message.error("Failed to copy link.");
      });
  };
  return (
    <div className="custom-video-container">
      {/* ReactPlayer component with full width and height */}
      <ReactPlayer
        url={row.file_url ? row.file_url : dummyVideo}
        loop
        width="100%"
        height="100vh"
        controls={false}
        playing={play}
        muted
      />
      {/* Top left overlay button */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3 }}
        className={``}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
          className="custom-overlay top-left-overlay"
        >
          <div className="custom-overlay-button">
            <h5 style={{marginBottom:"0px"}}>{row.title}</h5>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3 }}
        style={{
          justifyContent: "flex-start",
          bottom: "10px",
          left: "10px",
        }}
        className="bottom-left-popup circular-buttons-c"
      >
        {play ? (
          <button
            onClick={() => {
              setPlay(!play);
            }}
            className="my-lbutton"
          >
            <FaPause />
          </button>
        ) : (
          <button
            onClick={() => {
              setPlay(!play);
            }}
            className="my-lbutton"
          >
            <FaPlay />
          </button>
        )}
      </motion.div>

      {/* Bottom right overlay */}

      <motion.div
        initial={{ opacity: 0, x: 150 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 3 }}
        className={``}
      >
        <div className="custom-overlay bg-o-bbr bottom-right-overlay">
          <div
            className="flex-center"
          >
            <h5 className=" ">Telemission</h5>
          </div>
          <div className="b-r-main-c">
            <div className="share-icon-c-br">
              <Paragraph
                style={{ marginBottom: "0px" }}
                copyable={{
                  icon: [
                    <FaShareAlt key="copy-icon" />,
                    <FaShareAlt key="copied-icon" />,
                  ],
                  tooltips: ["Copy Link", "Copied!!"],
                  onCopy: handleCopy,
                }}
              ></Paragraph>
            </div>
            {row.channels ? (
              <div className="b-r-main-c1">
                {row.channels.map((c, ci) => {
                  return (
                    <a style={{ margin: "5px" }} href={c.url} target="blank">
                      <div className="icon-d-mmn">{icons[c.type]}</div>
                    </a>
                  );
                })}
              </div>
            ) : (
              <div style={{ width: "200px" }} className="b-r-main-c1"></div>
            )}
            {demo.person === undefined ? (
              <div>
                <AddPerson
                  row={row}
                  text={row.button_1_text ? row.button_1_text : "Watch"}
                />
              </div>
            ) : (
              <div>
                <Typewriter
                  options={{
                    strings: [`Launching ${demo.person?.name} into Game...`],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
