import React, { useContext, useEffect } from "react";
import SearchContext from "../Context/SearchContext";
import { Select } from "antd";
import { GoVideo } from "react-icons/go";
import { MdCamera } from "react-icons/md";
import { GrDocument } from "react-icons/gr";

import { AiOutlineNotification } from "react-icons/ai";
import {
  FaDatabase,
  FaFacebook,
  FaReddit,
  FaSteam,
  FaYoutube,
} from "react-icons/fa";

const { Option } = Select;

export default function DashboardType() {
  const { demo, setDemo } = useContext(SearchContext);

  return demo.data ? (
    <>
      {demo.activeDType === "marketing" ? (
        <Select
          value={demo.marketTypee === undefined ? "" : demo.marketTypee}
          onChange={(e) => {
            setDemo({
              ...demo,
              mrData: undefined,
              mrTotalP: undefined,
              mrPageNo: 1,
              marketTypee: e,
              callMarket: demo.callMarket ? demo.callMarket + 1 : 1,
            });
          }}
          style={{ minWidth: "120px", marginRight: "10px" }}
          className="transparentinput"
        >
          <Option value={""}>All</Option>
          <Option value={"facebook"}>
            <FaFacebook className="vid-top-dif-cs" />
            Facebook
          </Option>
          <Option value={"reddit"}>
            <FaReddit className="vid-top-dif-cs" />
            Reddit
          </Option>
          <Option value={"youtube"}>
            <FaYoutube className="vid-top-dif-cs" />
            Youtube
          </Option>
          <Option value={"steam"}>
            <FaSteam className="vid-top-dif-cs" />
            Steam
          </Option>
        </Select>
      ) : null}
      <Select
        value={demo.activeDType === undefined ? "sessions" : demo.activeDType}
        onChange={(e) => {
          setDemo({ ...demo, activeDType: e });
        }}
        style={{ minWidth: "130px",}}

        className="transparentinput"
      >
        <Option value={"sessions"}>
          <GoVideo className="vid-top-dif-cs" />
          Sessions
        </Option>
        <Option value={"snapshots"}>
          <MdCamera className="vid-top-dif-cs" />
          Snapshots
        </Option>
        <Option value={"marketing"}>
          <AiOutlineNotification className="vid-top-dif-cs" />
          Marketing
        </Option>
        <Option value={"assets"}>
          <FaDatabase className="vid-top-dif-cs" />
          Assets
        </Option>
        <Option value={"document"}>
          <GrDocument className="vid-top-dif-cs" />
          Document
        </Option>
        <Option value={"lab"}>
          <GrDocument className="vid-top-dif-cs" />
          Lab
        </Option>
        
      </Select>
    </>
  ) : null;
}
