import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import loadingVideo from "../../../../../images/generate-loading.mp4";
import { Progress } from "antd";

export default function GenerateLoadingVideo({ value, id , hide_p }) {
  const [videoDuration, setVideoDuration] = useState(15);
  const playerRef = useRef(null);

  useEffect(() => {
    let totalSeconds = 7.833333 * 1000;
    let oneWordTime = totalSeconds / 100;
    let maxPlay = value * oneWordTime;
    setVideoDuration(maxPlay);
  }, [value]);

  const adjustPlay = (a) => {
    let t = (videoDuration - 1000) / 1000;
    t = videoDuration - 1000 < 0 ? 0 : t;
    playerRef.current.seekTo(t, "seconds");
  };

  return (
    <div className="video-generate-load video-container radius-video">
      <ReactPlayer
        key={id}
        ref={playerRef}
        url={loadingVideo}
        controls={false}
        playing
        muted
        loop
        play
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        onProgress={(a, b) => {
          if (playerRef.current) {
            adjustPlay(a);
          }
        }}
      />
      {hide_p ? null : (
        <div className="progress-bc">
          <Progress active type="circle" percent={value} size={60} />
        </div>
      )}
    </div>
  );
}
