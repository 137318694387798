import { Button, Col, Row, Spin, Tag, Tooltip, message } from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import { BiSolidBrain } from "react-icons/bi";
import {
  FaArrowDown,
  FaArrowRight,
  FaDownload,
  FaInfoCircle,
  FaShareAlt,
} from "react-icons/fa";
import { MdClose, MdError } from "react-icons/md";
import ReactPlayer from "react-player";
import { LoadingOutlined } from "@ant-design/icons";
import GreenTick from "../../components/GreenTick";
import Typewriter from "typewriter-effect";
import logo from "../../images/Copy of ModelUI (256 x 256 px) (9).png";

import moment from "moment";
import RightSide from "./components/RightSide";
import Paragraph from "antd/es/typography/Paragraph";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import SearchContext from "../../Context/SearchContext";
import { useNavigate } from "react-router-dom";
export default function WatchStatus({ row }) {
  const { demo, setDemo, user } = useContext(SearchContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [dChat, setDChat] = useState(true);
  const [vectors, setVectors] = useState([]);
  const [pageNoV, setPageNoV] = useState(1);
  const [totalPagesV, settotalPagesV] = useState(1);
  const [activeId, setactiveId] = useState();
  const [instructions, setInstructions] = useState();
  const [showRight, setShowRight] = useState(true);
  const [vLoading, setVloading] = useState(true);
  const [showStartT, setShowStartT] = useState(true);
  const [callVector, setcallVector] = useState(1);
  const [tab, setTab] = useState("vector");
  const [topic, setTopic] = useState("");

  const [loading, setLoading] = useState();
  const [loading2, setLoading2] = useState(true);
  const playerRef = useRef(null);

  useEffect(() => {
    setVloading(true);
    getVector();
  }, [callVector]);
  const getVector = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/vector?stream_id=${row._id}&topic=${topic}&page=${pageNoV}&limit=20`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch vectors");
        }
        return response.json();
      })
      .then((data) => {
        setLoading2(false);
        setVloading(false);
        if (data && data.isSuccess) {
          settotalPagesV(data.totalPages);
          let all =
            pageNoV === 1 ? data.vectorList : vectors.concat(data.vectorList);
          setVectors(all);
        }
      })
      .catch((error) => {
        console.error("Error fetching vectors:", error.message);
      });
  };

  useEffect(() => {
    getInstructions(window.location.pathname.slice(11));
  }, []);

  const addView = (category, t) => {
    const obj = {
      user_id: user && user.user_id,
      feed_id: row._id,
      startTime: t,
      business_name: row.title,
      category,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/views`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response && response) {
        }
      });
  };

  const getInstructions = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/instruction?stream_id=${row._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setInstructions(data.instructionList);
        }
      });
  };
  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        <p>
          <b>
            {hours}:{minutes}:{seconds}
          </b>
        </p>
        <Button
          onClick={() => adjustVideoTime(d)}
          icon={<FaArrowRight />}
          type="primary"
          size="small"
          className="black-button"
        ></Button>
      </div>
    );
  };

  const adjustVideoTime = (seconds) => {
    // Ensure React Player reference is available
    if (playerRef.current) {
      // Seek to the specified time in seconds
      playerRef.current.seekTo(seconds, "seconds");
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#bedbff",
        marginLeft: "5px",
      }}
      spin
    />
  );
  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Live Instructions</p>
              <div class="dad-joke-prompt">
                <p>
                  Our instruction feature allows you to give real time
                  instructions to playtesters such as looking for an object,
                  finding issues or bugs and completing a task. There were no
                  real time instructions given for this session.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const vectorView = () => {
    return (
      <div id="container-vector" className="s3-tabs-c2">
        {vectors && vectors.length === 0 ? emptyAlert() : null}
        {vectors &&
          vectors.map((v, vi) => (
            <div
              id={`id${v.timestamp}`}
              key={vi}
              className={`vector-r-card ins-watch-c${
                activeId === `id${v.timestamp}` ? " active-v-c" : ""
              }`}
            >
              <img src={v.thumbnail} alt="" />
              <p className="max-150h-c">{v.content}</p>
              <div>{DynamicTimeDisplay(v.timestamp)}</div>
            </div>
          ))}
        {totalPagesV && totalPagesV > pageNoV ? (
          <div className="load-more-btn">
            {loading2 ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setPageNoV(pageNoV + 1);

                  // setLoading2(true);
                  setcallVector(callVector + 1);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
      </div>
    );
  };
  const handleCopy = () => {
    // Copy the URL to the clipboard
    const url = `https://telemit.com/${row._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Show success message
        message.success("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        message.error("Failed to copy link.");
      });
  };
  return (
    <div>
      <Header hideLeave={true} />
      <div
        style={{ paddingTop: "50px" }}
        className="container-my-20 my-container-100v"
      >
        <Row>
          <Col
            style={{ padding: isMobile ? "10px 0px 3px 0px" : "10px" }}
            lg={{ span: 16 }}
            md={{ span: 16 }}
            xl={{ span: 16 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div style={{ flexDirection: "column" }} className=" video-watch-c">
              {dChat ? null : (
                <div className="chat-open-icn">
                  <BiSolidBrain
                    onClick={() => {
                      setDChat(true);
                      setTimeout(() => {
                        setShowRight(true);
                      }, 500);
                    }}
                  />
                </div>
              )}
              <div
                style={{ height: "auto" }}
                className="video-container video-container-round"
              >
                <div className="flex-center">
                  <h5
                    style={{ margin: "20px 0px" }}
                    className="gradient-text-title"
                  >
                    {row.title}
                  </h5>
                </div>
                <ReactPlayer
                  ref={playerRef}
                  loop
                  url={row.file_url}
                  playing
                  play
                  muted
                  controls
                  onPause={(e) => {
                    if (playerRef.current) {
                      let t = playerRef.current.getCurrentTime();
                      addView("pause", Math.trunc(t));
                    }
                  }}
                  onPlay={(e) => {
                    if (playerRef.current) {
                      let t = playerRef.current.getCurrentTime();
                      addView("start", Math.trunc(t));
                    }
                  }}
                  onProgress={(a, b) => {
                    if (
                      vectors.filter(
                        (f) => f.timestamp === Math.floor(a.playedSeconds)
                      ).length
                    ) {
                      const targetDiv = document.getElementById(
                        `id${Math.floor(a.playedSeconds)}`
                      );
                      var container =
                        document.getElementById("container-vector");

                      if (targetDiv && container) {
                        // Calculate the y-coordinate of the target div relative to the container
                        var yOffset = targetDiv.offsetTop - container.offsetTop;
                        setactiveId(`id${Math.floor(a.playedSeconds)}`);
                        // Scroll to the target div smoothly
                        container.scrollTo({
                          top: yOffset,
                          behavior: "smooth",
                        });
                      } else {
                        console.error(
                          "Element with the specified id not found."
                        );
                      }
                    }
                  }}
                />
                <div className="flex-end-icons-">
                  <Paragraph
                    style={{ marginBottom: "0px" }}
                    copyable={{
                      icon: [
                        <FaShareAlt key="copy-icon" />,
                        <FaShareAlt key="copied-icon" />,
                      ],
                      tooltips: ["Copy Link", "Copied!!"],
                      onCopy: handleCopy,
                    }}
                  ></Paragraph>
                  {row.mode === "playtest" ? (
                    <Button
                      onClick={() => navigate(`/dashboard/document/${row.document}`)}
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                      className="grey-button"
                      size="small"
                    >
                      Documents
                    </Button>
                  ) : null}
                </div>
              </div>
              {isMobile ? null : (
                <div className="inst-date-c people-d-bvc">
                  <div className="flex-center">
                    <h5
                      style={{ marginBottom: "10px" }}
                      className="gradient-text-title"
                    >
                      Session Instructions
                    </h5>
                  </div>

                  <div className="ins-bewl-v">
                    <b>{row.instruction}</b>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      margin: "0px",
                      paddingBottom: "0px",
                    }}
                    className="space-between"
                  >
                    <div />

                    <div className="date-bx-righ">
                      {moment(row.date).format("MMMM Do YYYY")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <RightSide
            vLoading={vLoading}
            showRight={showRight}
            dChat={dChat}
            setDChat={setDChat}
            setShowRight={setShowRight}
            setTab={setTab}
            tab={tab}
            row={row}
            vectors={vectors}
            activeId={activeId}
            DynamicTimeDisplay={DynamicTimeDisplay}
            instructions={instructions}
            antIcon={antIcon}
            emptyAlert={emptyAlert}
            setTopic={setTopic}
            topic={topic}
            setcallVector={setcallVector}
            callVector={callVector}
            showSession={true}
            vectorView={vectorView}
            isMobile={isMobile}
          />
        </Row>
      </div>
    </div>
  );
}
