import { Button, Col, Input, Row, Spin } from "antd";
import React, { useState, useContext } from "react";
import SearchContext from "../../Context/SearchContext";
import logo from "../../images/2.png";
import { LoadingOutlined } from "@ant-design/icons";

export default function EmailVerification({ logout }) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const [email, setEmail] = useState(organization.root_email || ""); // use organization.root_email as the initial value
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationFailed, setverificationFailed] = useState(false);
  const [loadingV, setLoadingV] = useState(false);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const handleVerification = async () => {
    try {
      const requestVerification = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          email: organization.root_email,
          code: verificationCode,
        }),
      };

      const response = await fetch(
        "https://embed.axv.ai/api/verification/confirm",
        requestVerification
      );
      setLoadingV(false);
      if (response.ok) {
        updateOrganization("onboard", "pending");
        setOrganization({ ...organization, onboard: "pending" });
        // Verification successful
        setverificationFailed(false);
       
      } else {
        setverificationFailed(true);
        console.error("Verification failed");
      }
    } catch (error) {
      setLoadingV(false);

      console.error("Error during verification:", error);
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className="main-d-onb-c">
      <div className="logo-login-c">
        <img className="logo-img-bc" src={logo} alt="" />
      </div>
      <div className="intro-2nd-dc">
        <h4 style={{ fontFamily: "inter" }}>Email Verification</h4>

        {!verificationSuccess ? (
          <>
            <div>
              <p
                style={{
                  fontFamily: "inter",
                  color: "white",
                  marginBottom: "10px",
                }}
              >
                We've sent you a verification code to help verify your email.
              </p>
            </div>
            <form className="fonr-lgn-c">
              <div className="form-group mb-3">
                {/* <label for="inputEmail" class="sign-in-form-field-label">
                  Your Email *
                </label> */}
                <input
                  id="v-fname"
                  type="text"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required=""
                  autofocus=""
                  className="sign-in-form-input"
                />
              </div>
            </form>

            {verificationFailed ? (
              <Button className="red-button">Verification Failed</Button>
            ) : null}
          </>
        ) : (
          <>
            <p style={{ fontFamily: "inter", color: "green" }}>
              Email verification successful! You can now continue.
            </p>
          </>
        )}
      </div>

      <div className="up-bx-mnbcs">
        <Button
          onClick={() => {
            logout();
          }}
          type="primary"
        >
          Sign Out
        </Button>
        {loadingV ? (
          <Spin indicator={antIcon} />
        ) : (
          <Button
            onClick={() => {
              setLoadingV(true);
              handleVerification();
            }}
            className="black-button"
          >
            Verify Email
          </Button>
        )}
      </div>
    </div>
  );
}
