import { Progress, Tag } from "antd";
import React from "react";

export default function ScoreTab({ row }) {
  return (
    <div style={{ paddingBottom: "0px" }} className="ins-height-sc">
      {row ? (
        <div id="container-vector" className="s3-tabs-c2">
          {row.game_design ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Game Design</span>
                <Tag
                  style={{
                    color:
                      Number(row.game_design) > 80
                        ? "green"
                        : Number(row.game_design) > 60
                        ? "orange"
                        : Number(row.game_design) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.game_design}
                </Tag>
              </h6>
              <Progress
                percent={row.game_design}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.game_design) > 80
                    ? "green"
                    : Number(row.game_design) > 60
                    ? "orange"
                    : Number(row.game_design) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.accessibility ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Accessibility</span>
                <Tag
                  style={{
                    color:
                      Number(row.accessibility) > 80
                        ? "green"
                        : Number(row.accessibility) > 60
                        ? "orange"
                        : Number(row.accessibility) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.accessibility}
                </Tag>
              </h6>
              <Progress
                percent={row.accessibility}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.accessibility) > 80
                    ? "green"
                    : Number(row.accessibility) > 60
                    ? "orange"
                    : Number(row.accessibility) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.art_style ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Art Style</span>
                <Tag
                  style={{
                    color:
                      Number(row.art_style) > 80
                        ? "green"
                        : Number(row.art_style) > 60
                        ? "orange"
                        : Number(row.art_style) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.art_style}
                </Tag>
              </h6>
              <Progress
                percent={row.art_style}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.art_style) > 80
                    ? "green"
                    : Number(row.art_style) > 60
                    ? "orange"
                    : Number(row.art_style) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.fun ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Fun</span>
                <Tag
                  style={{
                    color:
                      Number(row.fun) > 80
                        ? "green"
                        : Number(row.fun) > 60
                        ? "orange"
                        : Number(row.fun) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.fun}
                </Tag>
              </h6>
              <Progress
                percent={row.fun}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.fun) > 80
                    ? "green"
                    : Number(row.fun) > 60
                    ? "orange"
                    : Number(row.fun) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.narrative_story ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Narrative</span>
                <Tag
                  style={{
                    color:
                      Number(row.narrative_story) > 80
                        ? "green"
                        : Number(row.narrative_story) > 60
                        ? "orange"
                        : Number(row.narrative_story) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.narrative_story}
                </Tag>
              </h6>
              <Progress
                percent={row.narrative_story}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.narrative_story) > 80
                    ? "green"
                    : Number(row.narrative_story) > 60
                    ? "orange"
                    : Number(row.narrative_story) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.sound_music ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Sound</span>
                <Tag
                  style={{
                    color:
                      Number(row.sound_music) > 80
                        ? "green"
                        : Number(row.sound_music) > 60
                        ? "orange"
                        : Number(row.sound_music) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.sound_music}
                </Tag>
              </h6>
              <Progress
                percent={row.sound_music}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.sound_music) > 80
                    ? "green"
                    : Number(row.sound_music) > 60
                    ? "orange"
                    : Number(row.sound_music) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.technical ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>Technical</span>
                <Tag
                  style={{
                    color:
                      Number(row.technical) > 80
                        ? "green"
                        : Number(row.technical) > 60
                        ? "orange"
                        : Number(row.technical) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.technical}
                </Tag>
              </h6>
              <Progress
                percent={row.technical}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.technical) > 80
                    ? "green"
                    : Number(row.technical) > 60
                    ? "orange"
                    : Number(row.technical) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
          {row.user_experience ? (
            <div className={`vector-r-card ins-watch-c score-h-s`}>
              <h6>
                <span>User Experience</span>
                <Tag
                  style={{
                    color:
                      Number(row.user_experience) > 80
                        ? "green"
                        : Number(row.user_experience) > 60
                        ? "orange"
                        : Number(row.user_experience) > 40
                        ? "yellow"
                        : "red",
                  }}
                  color="black"
                >
                  {row.user_experience}
                </Tag>
              </h6>
              <Progress
                percent={row.user_experience}
                size="small"
                status="active"
                showInfo={false}
                strokeColor={
                  Number(row.user_experience) > 80
                    ? "green"
                    : Number(row.user_experience) > 60
                    ? "orange"
                    : Number(row.user_experience) > 40
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
