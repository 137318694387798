import { Button, Drawer, Input, Popconfirm, Tag, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdMessage } from "react-icons/md";
import SearchContext from "../../../../../Context/SearchContext";
import GenerateLoadingVideo from "./GenerateLoadingVideo";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { FaMagic } from "react-icons/fa";

export default function AssetChat({ row }) {
  const { user, setShowPrice } = useContext(SearchContext);
  const [show, setShow] = useState();
  const [text, setText] = useState("");
  const [chat, setChat] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    if (show) {
      getImages();
    }
  }, [show, callApi]);

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${pageNo}&limit=20&user_id=${
        user && user.user_id
      }&task_id=${row.task_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setChat(data.imageList);
          setTotalPages(data.totalPages);
          setTimeout(() => {
            
            if (scroll) {
              setScroll(false);
              scrollToElement(row._id);
            }
          }, 100);

          if (pageNo === 1) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 5000);
          }
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  function scrollToElement(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      var offsetTop = element.offsetTop;
      console.log(element.scrollHeight , offsetTop)
      element.scrollTop = element.scrollHeight;
     
    }
  }

  const createTask = () => {
    const obj = {
      query: text,
      user_id: user && user.user_id,
      task_id: row.task_id,

      workspace: window.location.pathname.includes("/space/")
        ? window.location.pathname.slice(7)
        : undefined,
      // huminoid_id: behavior.selected?._id ? behavior.selected?._id : "auto",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setText("");
          setLoading(false);
          setShowPrice(true);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        setText("");
        setLoading(false);
        if (data.data) {
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  const videoCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo
            hide_p={true}
            value={m.progress_bar}
            id={m._id}
          />
        ) : (
          <div className="video-thumbnail-imgg">
            <div
              style={{ position: "relative" }}
              className="video-container video-container-round"
            >
              <ReactPlayer url={m.file_url} controls />
            </div>
            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const csvCard = () => {
    return <div></div>;
  };
  const codeCard = () => {
    return <div></div>;
  };
  const textCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo
            hide_p={true}
            value={m.progress_bar}
            id={m._id}
          />
        ) : (
          <div className="video-thumbnail-imgg">
            <p>{m.text_generation}</p>
          </div>
        )}
      </div>
    );
  };
  const mapCard = () => {
    return <div></div>;
  };
  const ImageCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo
            hide_p={true}
            value={m.progress_bar}
            id={m._id}
          />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.file_url} alt="" />

            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const AudioCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg ">
          <img src={m.root_image} alt="" />

          <div
          // className=" audio-player-mn-bx"
          // ref={ref10}
          >
            {/* <h5>{m.media_type}</h5> */}
            <ReactAudioPlayer src={m.file_url} controls />
            {/* <h6>{m.query}</h6> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Tooltip>
        <b
          onClick={() => {
            setShow(row);
          }}
          className="show-hover"
        >
          <MdMessage
            style={{
              position: "relative",
              zIndex: 120,
            }}
            className="circular-icon hover"
          />
        </b>
      </Tooltip>
      <Drawer
        open={show !== undefined}
        onClose={() => {
          setShow();
          setChat()
          setScroll(true);

        }}
        width={500}
        className="black-drawer"
      >
        <div className="chat-main-d">
          <div id={row._id} className="chat-bx-mnd">
            {chat &&
              chat.map((c, ci) => {
                return (
                  <div key={ci}>
                    <div
                      style={{
                        display:
                          ci > 0 && chat[ci - 1].query === c.query
                            ? "none"
                            : "flex",
                      }}
                      className="user-q-chat"
                    >
                      <div>
                        <p>{c.query}</p>
                      </div>
                    </div>
                    <div className="user-r-chat">
                      {c.media_type === "original" ? videoCard(c) : null}
                      {c.media_type === "csv" ? csvCard(c) : null}
                      {c.media_type === "code" ? codeCard(c) : null}
                      {c.media_type === "text" ? textCard(c) : null}
                      {c.media_type === "map" ? mapCard(c) : null}
                      {c.media_type === "image" ? ImageCard(c) : null}
                      {c.media_type === "sound" ||
                      c.media_type === "voice" ||
                      c.media_type === "music"
                        ? AudioCard(c)
                        : null}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="chat-bx-input-c">
            <Input.TextArea
              placeholder="Type..."
              className="grey-input"
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div
              className={`icon-left-ed   active-i-l-ed`}
              style={{ width: "34px" }}
              onClick={() => {
                if (!loading) {
                  createTask();
                  setLoading(true);
                }
              }}
            >
              <FaMagic />
              {/* <b>Search</b> */}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
