import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowRight, FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/Copy of ModelUI (256 x 256 px) (9).png";

export default function SessionTab({ row }) {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState();
  const [callVector, setCallVector] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    getSessions();
  }, [callVector]);

  const getSessions = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/feed?temp_id=${row.temp_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch vectors");
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setSessions(data.feedList);
        }
      })
      .catch((error) => {
        console.error("Error fetching vectors:", error.message);
      });
  };
  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Related Sessions</p>
              <div class="dad-joke-prompt">
                <p>
                  There are no related sessions for this instruction. Please
                  head to our console to create another session using this
                  asset.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const DynamicTimeDisplay = (d, id) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        <p>
          {hours}:{minutes}:{seconds}
        </p>
        <Button
          onClick={() => {
            navigate(`/${id}`);
            window.location.reload();
          }}
          icon={<FaArrowRight />}
          type="primary"
          size="small"
        ></Button>
      </div>
    );
  };
  return loading ? (
    <div style={{ width: "100%", height: "100%" }} className="loader-video-img">
      <img src={logo} alt="" />
    </div>
  ) : (
    <div style={{paddingBottom:"0px"}} className="ins-height-sc">
      {sessions && sessions.length === 0 ? emptyAlert() : null}
      <div id="container-vector" className="s3-tabs-c2">
        {sessions &&
          sessions.map((v, vi) => (
            <div
              id={`id${v.time_length}`}
              key={vi}
              className={`vector-r-card ins-watch-c ${
                v._id === row._id ? " active-card-ses" : ""
              }`}
            >
              <img src={v.thumbnail} alt="" />
              <p className="max-150h-c">{v.title}</p>
              <div>{DynamicTimeDisplay(v.time_length, v._id)}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
