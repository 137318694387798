import { Button, Input, Modal, Spin, Table, Tag, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowRight, FaPlus, FaQuestionCircle } from "react-icons/fa";
import FullLoader from "../../../components/FullLoader";
import { VscFeedback } from "react-icons/vsc";

import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";


export default function DocumentsTable({ count }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [rolePlay, setrolePlay] = useState();
  const [loading, setLoading] = useState(true);
  const [isStatus, setIsStatus] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [callAPI, setCallAPI] = useState(1);
  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    getrolePlay();
  }, [show, pageNo, callAPI]);

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = rolePlay.map((m) => (m._id === row._id ? row : m));
    setrolePlay(allRow);
  };
  const getrolePlay = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${pageNo}&limit=6&sort_by=${-1}&feed_type=document&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "gold",
        marginRight: "10px",
      }}
      spin
    />
  );
  const gamingColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,

      render: (text, row) => <b>{moment(text).format("MMM Do yy")} </b>,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Name"
          onChange={(e) => {
            changeField("title", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ title: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Version Id",
      dataIndex: "version_id",
      key: "version_id",
      width: 120,

      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Format",
      dataIndex: "access",
      key: "access",
      width: 80,

      render: (text, row) => (
        <Tag color="#d46b08">
          <VscFeedback /> {text}{" "}
        </Tag>
      ),
    },

    {
      title: "",
      dataIndex: "01",
      key: "01",
      width: 10,

      render: (text, row) => (
        <div
          onClick={() => {
            navigate(`/dashboard/document/${row._id}`);
          }}
          className="icon-circle-br-t"
        >
          <FaArrowRight size={18} />
        </div>
      ),
    },
  ];

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };
  return (
    <>
      {/* <button
        onClick={() => {
          if (count?.processing > 0) {
            setLoading(true)
            setShow(true);
          }
        }}
        className={`my-lbutton fle-x-c ${
          count?.processing > 0 ? " orange-my-lb " : " red-my-lb "
        }`}
      >
        <TbProgress className="ani-for-cirlce" /> Processing{" "}
        <span className="circle-nmbr">{count?.processing}</span>
        <FaArrowRight className="arrow-right-c" />
      </button> */}

      <div style={{ minHeight: "300px" }}>
        {loading ? (
          <FullLoader />
        ) : (
          <Table
            className="scrol-tabel-c black-table pagination-white"
            columns={gamingColumns}
            dataSource={rolePlay}
            pagination={{
              pageSize: 6,
              current: pageNo,
              total: totalPages * 6,
              onChange: (v) => {
                setrolePlay();
                setPageNo(v);
              },
              size: "small",
              showSizeChanger: false,
            }}
          />
        )}
      </div>
    </>
  );
}
