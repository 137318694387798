import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  Row,
  Spin,
  Tag,
  message,
  Tabs,
  Popconfirm,
  Input,
  Select,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowDown,
  FaExpandAlt,
  FaMinus,
  FaPhotoVideo,
  FaPlay,
  FaRegCircleXmark,
} from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import FullLoader from "../../../components/FullLoader";
import { LuCircleDashed } from "react-icons/lu";
import Header from "../../../components/Header";
import moment from "moment";
import { TbPencilMinus } from "react-icons/tb";
import GreenTick from "../../../components/GreenTick";
import { MdErrorOutline } from "react-icons/md";
import FeedPopup from "./FeedPopup";
import { IoMdCheckmarkCircle } from "react-icons/io";
import Sessions from "./Sessions";

const { Option } = Select;
export default function DashboardDocuments() {
  const navigate = useNavigate();
  const [loading, setLoadeing] = useState(true);

  const [vData, setvData] = useState();
  const [feed, setFeed] = useState();
  const [vPageNo, setvvPageNo] = useState(1);
  const [vTotalP, setvvTotalP] = useState();
  const [active, setActive] = useState();
  const [act, setAct] = useState();
  const [activeRow, setActiveRow] = useState();
  const [isChange, setIsChange] = useState(false);

  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getlist(u, window.location.pathname.slice(20));
      if (feed === undefined) {
        getFeed(u, window.location.pathname.slice(20));
      }
    } else {
      navigate("/");
    }
  }, [callApi, vPageNo, feed?.sParameter]);

  useEffect(() => {
    if (activeRow) {
      getFeedActive(activeRow.feed_id);
    }
  }, [act]);

  const getFeed = (u, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.feedData) {
          setFeed(data.feedData);
        }
      });
  };
  const getFeedActive = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.feedData) {
          setActive(data.feedData);
        }
      });
  };

  const getlist = (u, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/block?page=${vPageNo}&limit=30&document_id=${id}&parameter=${
        feed?.sParameter ? feed?.sParameter : ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];

          if (vPageNo > 1) {
            all = vData.concat(data.blockList);
          } else {
            all = [...data.blockList];
          }

          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setvvTotalP(data.totalPages);
          setvData(all);
        }

        setLoadeing(false);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = vData.map((m) => (m._id === row._id ? row : m));
    setvData(allRow);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`https://embed.axv.ai/api/block/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };
  const selectStatus = (r) => {
    return (
      <Select
        value={r.status}
        onChange={(v) => {
          changeField("status", v, r);
          saveChanges({ status: v }, r._id, true);
        }}
        className="black-select"
        style={{ width: "150px" }}
      >
        <Option value="error">
          <div className="drop-select-flex">
            <MdErrorOutline color="red" size={20} />{" "}
            <span className="text-drop-s"> Error</span>
          </div>
        </Option>
        <Option value="pending">
          <div className="drop-select-flex">
            <LuCircleDashed size={20} />
            <span className="text-drop-s"> Pending</span>
          </div>
        </Option>
        <Option value="progress">
          <div className="drop-select-flex">
            <LuCircleDashed color="yellow" size={20} className="circle" />{" "}
            <span className="text-drop-s"> Progress</span>
          </div>
        </Option>
        <Option value="complete">
          <div className="drop-select-flex">
            <IoMdCheckmarkCircle color="yellow" size={20} />
            <span className="text-drop-s"> Complete</span>
          </div>
        </Option>
      </Select>
    );
  };
  const videoContent = (r, i) => {
    return (
      <div className="video-contc">
        <div className="video-contc-t-i">
          <div className="flex-t-c-dc">
            <div className="number-cir-cvc2">
              <FaPhotoVideo />
            </div>
            <b>{r.parameter}</b>
            {r.view === "closed" ? (
              <div style={{ marginLeft: "20px" }} className="flex">
                {selectStatus(r)}
                <FaExpandAlt
                  style={{ marginLeft: "15px", marginTop: "8px" }}
                  className="hover"
                  onClick={(v) => {
                    changeField("view", "open", r);
                    saveChanges({ view: "open" }, r._id, true);
                  }}
                />
              </div>
            ) : null}
          </div>
          {r.view === "closed" ? (
            r.log_type === "content" ? null : (
              <div
                onClick={() => {
                  setActive();
                  setActiveRow(r);
                  setAct(!act);
                }}
                style={{ marginLeft: "15px" }}
                className="icon-circle-br-t"
              >
                <FaPlay />
              </div>
            )
          ) : (
            <div className="number-cir-cvc">{i + 1}</div>
          )}

          {r.view === "open" ? (
            <div className="number-cir-cvc232">
              <FaMinus
                className="hover"
                onClick={(v) => {
                  changeField("view", "closed", r);
                  saveChanges({ view: "closed" }, r._id, true);
                }}
              />
            </div>
          ) : null}
        </div>

        {r.view === "open" ? (
          <div className="content-cvc">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 30 }}
              value={r.content}
              placeholder="Content"
              onChange={(e) => {
                changeField("content", e.target.value, r);
                setIsChange(true);
              }}
              onBlur={() => saveChanges({ content: r.content }, r._id)}
              className="transparent-textarea"
            />
          </div>
        ) : null}
        {r.view === "open" ? (
          <div className="space-between">
            {selectStatus(r)}
            {r.log_type === "content" ? (
              <span />
            ) : (
              <div className="play-btn-icnsvc">
                <div
                  onClick={() => {
                    setActive();
                    setActiveRow(r);
                    setAct(!act);
                  }}
                  style={{ marginLeft: "15px" }}
                  className="icon-circle-br-t"
                >
                  <FaPlay />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const vectorData = () => {
    return (
      <div style={{ overflowY: "clip" }} className="sumilation-main-d">
        <Header />

        <div className="container">
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
              style={{ padding: "5px" }}
            >
              {/* <h3 className="text-c-dds">Document</h3> */}
              {feed ? (
                <div
                  style={{ maxHeight: "80vh", overflowY: "auto" }}
                  className="feeed-t-div-cs"
                >
                  <div className="feeed-t-c-top yellow-card">
                    <b style={{ color: "black" }}>{feed.mode}</b>
                  </div>
                  <div className="feeed-t-c-top">
                    <Tag
                      onClick={() => {
                        setFeed({ ...feed, sParameter: "" });
                      }}
                      className={`${
                        feed?.sParameter === "" ||
                        feed?.sParameter === undefined
                          ? " yellow-tag"
                          : "black-tag"
                      }`}
                    >
                      All
                    </Tag>
                    {feed.tags &&
                      feed.tags.map((t, ti) => {
                        return (
                          <Tag
                            onClick={() => {
                              setFeed({ ...feed, sParameter: t });
                            }}
                            className={`${
                              feed?.sParameter === t
                                ? " yellow-tag"
                                : "black-tag"
                            }`}
                            key={ti}
                          >
                            {t}
                          </Tag>
                        );
                      })}
                  </div>

                  <div className="feeed-t-c-top">
                    <b>{feed.title}</b>
                  </div>
                  <div className="feeed-t-c-top">
                    {feed.description ? (
                      <div>{feed.description}</div>
                    ) : (
                      <div className="empty-desc-c">Empty Description</div>
                    )}
                  </div>

                  <div className="feeed-t-c-top">
                    {moment(feed.date).fromNow()}
                  </div>
                  <Sessions
                    feed={feed}
                    setAct={setAct}
                    setActive={setActive}
                    setActiveRow={setActiveRow}
                    act={act}
                  />
                  {active ? (
                    <FeedPopup
                      active={active}
                      setActive={setActive}
                      setActiveRow={setActiveRow}
                      activeRow={activeRow}
                    />
                  ) : null}
                </div>
              ) : null}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              lg={{ span: 16 }}
              md={{ span: 16 }}
              style={{ padding: "5px" }}
            >
              <h3 className="text-c-dds">Observations</h3>

              {vData && vData.length === 0 ? (
                <div className="no-heigh-div-c editor-container">
                  <div className="card21">
                    <div className="card21-img"></div>
                    <div className="card21-info">
                      <p className="text21-body">No Document</p>
                      <p className="txt-card-21">
                        There are currently no Document for your games.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div
                style={{ maxHeight: "75vh", overflowY: "auto" }}
                className="assets-bx-h1"
              >
                {vData === undefined ? (
                  <div className="box-loading">
                    <FullLoader />
                  </div>
                ) : null}

                {vData &&
                  vData.map((m, mi) => {
                    return (
                      <Row key={mi}>
                        <Col
                          xs={{ span: 0 }}
                          sm={{ span: 0 }}
                          lg={{ span: 3 }}
                          md={{ span: 2 }}
                        ></Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          lg={{ span: 21 }}
                          md={{ span: 22 }}
                          style={{ padding: "5px" }}
                        >
                          {m.log_type === "video_content" ||
                          m.log_type === "content"
                            ? videoContent(m, mi)
                            : null}
                        </Col>
                      </Row>
                    );
                  })}
                {vTotalP && vTotalP > vPageNo ? (
                  <div
                    style={{ justifyContent: "flex-end" }}
                    className="load-more-btn"
                  >
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <Tag
                        onClick={() => {
                          setvvPageNo(vPageNo + 1);

                          setLoadeing(true);
                        }}
                        className={`editor-tag-tab  active-tg-wdt`}
                      >
                        Load More <FaArrowDown />
                      </Tag>
                    )}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return vectorData();
}
