import { Button, Col, Modal, Row, message } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React, { useContext } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import TagsGroup from "../../../../../components/TagsGroup";
import moment from "moment";
import SearchContext from "../../../../../Context/SearchContext";

export default function DetailModal({ show, setShow }) {
  const { images, setImages, setTasks, tasks } = useContext(SearchContext);

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = images.data.map((m) =>
            m._id === id ? response.imageData : m
          );
          setImages({ ...images, data: all });
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const copyToClipboard = () => {
    const textToCopy = `${window.location.origin}/share/${show._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  return (
    <Modal
      open={show !== undefined}
      onCancel={() => setShow()}
      width={show?.media_type === "text" ? 500 : 1000}
      className="no-buttons-modal black-modal"
    >
      <h5>Detail</h5>
      {show ? (
        show?.media_type === "text" ? (
          <div className="blank-page-imhg-c">
            <p>{show.text_generation}</p>
          </div>
        ) : (
          <Row>
            <Col lg={{ span: 13 }} sm={{ span: 24 }} md={{ span: 13 }}>
              {" "}
              <div className="">
                {show?.media_type === "image" ? (
                  <div className="blank-page-imhg-c">
                    <img className="content-img-m" src={show.file_url} alt="" />
                  </div>
                ) : null}
                {show?.media_type === "original" ? (
                  <div className="video-container video-container-round">
                    <ReactPlayer playing url={show.file_url} controls />
                  </div>
                ) : null}
                {show?.media_type === "sound" ||
                show?.media_type === "voice" ||
                show?.media_type === "music" ? (
                  <div
                    style={{ height: "240px" }}
                    className="video-thumbnail-img img-2-sx audio-bx-blank"
                  >
                    <img src={show.root_image} alt="" />
                    <div
                      className=" audio-player-mn-bx"
                      // ref={ref10}
                    >
                      {/* <h5>{m.media_type}</h5> */}
                      <ReactAudioPlayer src={show.file_url} controls />
                      {/* <h6>{m.query}</h6> */}
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col lg={{ span: 11 }} sm={{ span: 24 }} md={{ span: 11 }}>
              <div className="tags-date-area right-area-det-m">
                <table>
                  <tr>
                    <td>
                      <b>Query:</b>
                    </td>
                    <td>{show.query ? show.query : "No Query"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Status:</b>
                    </td>
                    <td>{show.display ? show.display : "Private"}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Date:</b>
                    </td>
                    <td>{moment(show.createdAt).fromNow()}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Share:</b>
                    </td>
                    <td>
                      <b className="hover" onClick={copyToClipboard}>
                        Click to Copy
                      </b>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Tags:</b>
                    </td>
                    <td>
                      <TagsGroup
                        tags={show.count ? show.count : []}
                        color="#BC1823"
                        onTagChange={(v) => {
                          updateValue({ count: v }, show?._id);

                          setShow({
                            ...show,
                            count: v,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        )
      ) : null}
    </Modal>
  );
}
