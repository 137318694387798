import React from "react";
import logo from "../images/Copy of ModelUI (256 x 256 px) (9).png";
import Typewriter from "typewriter-effect";
import "./components.css"
export default function EmptyTyping() {
  return (
    <div>
      <div class="terminal-loader">
        <div class="terminal-header">
          <div class="terminal-title">Telemission</div>
          <div class="terminal-controls">
            <div class="control close"></div>
            <div class="control minimize"></div>
            <div class="control maximize"></div>
          </div>
        </div>
        <div>
          {" "}
          <img
            style={{ marginTop: "20px" }}
            className="logo-img-bc wheel"
            src={logo}
            alt=""
          />
        </div>

        <>
          <div style={{ marginTop: "30px" }}>
            <Typewriter
              options={{
                strings: ["No telemission found. Computer build failed."],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <Typewriter
              options={{
                strings: ["Deploy a telemission"],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <a href="https://www.telemit.com">
              <button className="my-lbutton">Telemit</button>
            </a>
          </div>
        </>
      </div>
    </div>
  );
}
