import { Button, Input, Modal, Spin, Upload, notification } from "antd";
import React, { useContext, useState } from "react";

import SearchContext from "../../../Context/SearchContext";

import { LoadingOutlined } from "@ant-design/icons";
import { FaDatabase } from "react-icons/fa";

let all = [];

export default function AssetsModal() {
  const { user, demo, setDemo } = useContext(SearchContext);
  const [loading, setloading] = useState(false);
  const [UploadedFile, setUplaoded] = useState([]);
  const [show, setShow] = useState();
  const [upLoading, setUpLoading] = useState(false);
  // formData.append("file", UploadedFile[0].originFileObj);

  const props = {
    name: "picture-card",
    status: "done",
    listType: "picture-card",
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    fileList: show?.upload,
    onChange(info) {
      setShow({ ...show, upload: info.fileList });
    },
  };
  const saveFile = (f) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.upload.length ? f.upload[0].originFileObj : "");

    formData.append("purpose", "assets");
    // formData.append("version_id", demo.activeBlock ? demo.activeBlock : "");

    formData.append("content", f.name);
    formData.append("description", f.description);

    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setloading(false);
        setShow();

        if (data.message === "Compute is not available") {
        }
        if (data && data.isSuccess) {
          setDemo({
            ...demo,
            callAssets: demo.callAssets ? demo.callAssets + 1 : 1,
          });
          setShow();
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
          setUpLoading();
        } else {
          setUpLoading();
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 32,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <button className="my-lbutton gold-btn-c" onClick={() => setShow({})}>
        <FaDatabase /> Create
      </button>
      <Modal
        width={700}
        open={show !== undefined}
        onCancel={() => {
          setShow();
        }}
        className={`no-f-no-h top-20-x  brand-modal `}
      >
        <div className="no-heigh-div-c editor-container">
          <div style={{paddingTop:"15px" , marginBottom:"30px"}} className="card21">
            <div className="card21-info">
              <p className="text21-body">Game Asset</p>
              <p className="txt-card-21">
                Upload your game assets here to manage, test and enhance your
                game's content efficiently and seamlessly
              </p>
            </div>
          </div>
        </div>
        <div className=" form-pf">
          <div className="game-show-p1">
            <div>
              <Upload maxCount={1} {...props}>
                Upload
              </Upload>
              <label style={{ margin: "15px 0px" }} for="name" class="label-pf">
                <span class="title-pf">Name *</span>
                <Input
                  placeholder="Name"
                  onChange={(e) => {
                    setShow({
                      ...show,

                      name: e.target.value,
                    });
                  }}
                  value={show?.name}
                  style={{ width: "100%" }}
                  class="input-field-pf"
                />
              </label>
              <label style={{ margin: "15px 0px" }} for="name" class="label-pf">
                <span class="title-pf">Description *</span>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  placeholder="Description"
                  onChange={(e) => {
                    setShow({
                      ...show,

                      description: e.target.value,
                    });
                  }}
                  value={show?.description}
                  style={{ width: "100%" }}
                  size="large"
                />
              </label>
              {show?.name && show?.name.length && show?.upload ? (
                <div className="save-btn-pl flex-end">
                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <Button
                      onClick={() => {
                        setloading(true);
                        saveFile(show);
                      }}
                      size="large"
                      className="glow-on-hover"
                      style={{
                        maxWidth: "300px",
                      }}
                    >
                      Start
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
