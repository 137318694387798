import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Row, Spin, Tag, message, Tabs } from "antd";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowDown, FaArrowRight, FaGamepad, FaPlay } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import FullLoader from "../../components/FullLoader";
import SearchContext from "../../Context/SearchContext";
import Header from "../../components/Header";
import image1 from "../../images/FznC6ff.jpg";
import { GiDragonOrb } from "react-icons/gi";
import StartModal from "./components/StartModal";
import FeedPopup from "./components/FeedPopup";

import OnboardSuccessModal from "./components/OnboardSuccessModal";
import Blocks from "./components/Blocks";
import MarketingType from "./components/MarketingType";
import AssetesType from "./components/AssetesType";
import AssetsModal from "./components/AssetsModal";
import MarketModal from "./components/MarketModal";
import DocumentsTable from "./components/DocumentsTable";
import Editor from "../editor/Editor";

export default function Dashboard() {
  const { user, gamesData, setGamesData, demo, setDemo, count, setCount } =
    useContext(SearchContext);

  const navigate = useNavigate();

  const [loading, setLoadeing] = useState(true);
  const [loading2, setLoadeing2] = useState(true);
  // const [showVector, setShowVector] = useState(false);
  const [search, setsearch] = useState("");
  const [vData, setvData] = useState();
  const [vPageNo, setvvPageNo] = useState(1);
  const [vTotalP, setvvTotalP] = useState();
  const [active, setActive] = useState();
  const [activeRow, setActiveRow] = useState();
  const [callActive, setCallActive] = useState();

  const [callApi, setCallApi] = useState(1);
  const [callCount, setCallCount] = useState(1);
  const { TabPane } = Tabs;
  useEffect(() => {
    // Store the original background image
    const originalBackgroundImage = document.body.style.backgroundImage;

    // Set the background image to none
    document.body.style.backgroundImage = `url(${image1})`;

    // Cleanup function to restore the original background image
    return () => {
      document.body.style.backgroundImage = originalBackgroundImage;
    };
  }, []);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getActiveData(u);
    } else {
      navigate("/");
    }
  }, [callCount]);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getlist(u);
    }
  }, [demo.activeBlock, callApi]);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (demo.activeDType === "snapshots" && search === "") {
      getVectorsList(u);
    }
  }, [demo.activeDType, search, vPageNo]);
  useEffect(() => {
    if (callActive) {
      setActiveRow(callActive);
      setActive({});
      getActiveFeed(`/${callActive.stream_id}`);
    }
  }, [callActive]);

  const getActiveData = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed/count?user_id=${u?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTimeout(() => {
            setCallCount(callCount + 1);
          }, 10000);
          setCount(data);
        }
      });
  };
  const getActiveFeed = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setActive(data.feedData);
        }
      });
  };
  const getVectors = (u) => {
    const obj = {
      query: u,
      user_id: user?.user_id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadeing2(false);
        if (data) {
          setvvPageNo(1);
          setvvTotalP();
          setvData(data);
        }
      });
  };

  const getVectorsList = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/vector?page=${vPageNo}&limit=12&user_id=${
        u?.user_id
      }&version_id=${demo.activeBlock ? demo.activeBlock : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing2(false);

        if (data && data.isSuccess) {
          let all = [];
          if (vPageNo > 1) {
            all = vData.concat(data.vectorList);
          } else {
            all = [...data.vectorList];
          }

          setvvTotalP(data.totalPages);
          setvData(all);
        }
      });
  };
  const getlist = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${
        gamesData.pageNoMemo
      }&limit=12&status=watch&user_id=${u?.user_id}&version_id=${
        demo.activeBlock ? demo.activeBlock : ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (gamesData.pageNoMemo && gamesData.pageNoMemo > 1) {
            all = gamesData.memoryData.concat(data.feedList);
          } else {
            all = [...data.feedList];
          }
          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setGamesData({
            ...gamesData,
            memoryData: all,

            totalPagesMemo: data.totalPages,
          });
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");

          const all = gamesData.oldData2.filter((p) => p._id !== row._id);

          setGamesData({
            ...gamesData,
            memoryData: all,

            totalPagesMemo: data.totalPages,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        {hours}:{minutes}:{seconds}
      </div>
    );
  };
  const vectorData = () => {
    return (
      <div>
        {vData && vData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Snapshots</p>
                <p className="txt-card-21">
                  There are currently no snapshots for any of your builds.
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="assets-bx-h1">
          {vData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {vData &&
              vData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail ? m.thumbnail : image1} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>

                      <div className="public-top-c2">
                        {m.tags ? (
                          <div>
                            {m.tags?.slice(0, 4).map((t, ti) => {
                              return (
                                <Tag color="black" key={ti}>
                                  {t}
                                </Tag>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => {
                            if (active) {
                              setActiveRow();
                              setActive();

                              setCallActive(m);
                            } else {
                              setActiveRow(m);
                              setActive({});
                              getActiveFeed(`/${m.stream_id}`);
                            }
                          }}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          {vTotalP && vTotalP > vPageNo ? (
            <div className="load-more-btn">
              {loading2 ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setvvPageNo(vPageNo + 1);

                    setLoadeing2(true);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const feedData = () => {
    return (
      <div>
        {gamesData.memoryData && gamesData.memoryData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Sessions Completed</p>
                <p className="txt-card-21">
                  There are currently no sessions completed for your games.
                  Please create a session using the button below
                </p>
                <div>
                  <Button className="black-button btn-top-20"> Create</Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="assets-bx-h1">
          {gamesData.memoryData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {gamesData.memoryData &&
              gamesData.memoryData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail ? m.thumbnail : image1} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>

                      <div className="public-top-c2">
                        <div className="btm-c-mem-crd">
                          <span>{m.mode ? m.mode : "Playtest"}</span>
                          {m.time_length ? (
                            <span>{DynamicTimeDisplay(m.time_length)}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => navigate(`/${m._id}`)}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          {gamesData.totalPagesMemo &&
          gamesData.totalPagesMemo > gamesData.pageNoMemo ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      pageNoMemo: gamesData.pageNoMemo + 1,
                    });
                    setLoadeing(true);
                    setCallApi(callApi + 1);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="sumilation-main-d">
      <Header />
      <OnboardSuccessModal />
      <div className="container">
        {demo.activeDType === "sessions" ||
        demo.activeDType === undefined ||
        demo.activeDType === "snapshots" ? (
          <div className="center-c-flx">
            <div className="input-main-search-ccs">
              <input
                value={search}
                onChange={(e) => {
                  setsearch(e.target.value);
                  if (e.target.value.length) {
                    getVectors(e.target.value);
                    setDemo({ ...demo, activeDType: "snapshots" });
                  } else {
                    setvData();
                    // setShowVector(false);
                    setsearch(e.target.value);
                  }
                }}
                required=""
                type="text"
                name="text"
                class="-stpinput"
              />
              {search && search.length ? null : (
                <label class="-stplabel">Explore</label>
              )}
              <GiDragonOrb className="iox-exp" />
            </div>
          </div>
        ) : null}

        <div
          style={{ width: "100%", marginTop: "20px" }}
          className="active-goals-tc21 "
        >
          {demo.activeDType !== "lab" ? (
            <div
              style={{
                padding: "20px 20px 10px 20px",
                justifyContent: "space-between",
              }}
              className="btm-btns-tb-2"
            >
              <div className="flex">
                {demo.activeDType === "sessions" ||
                demo.activeDType === undefined ||
                demo.activeDType === "snapshots" ? (
                  <StartModal />
                ) : null}
                {demo.activeDType === "assets" ? <AssetsModal /> : null}
                {demo.activeDType === "marketing" ? <MarketModal /> : null}
                {/* <LiveTable count={count} />
              <ProcessingTable count={count} /> */}
              </div>

              <div className="flex">
                <Blocks />
              </div>
            </div>
          ) : null}

          {demo.activeDType === "snapshots" ? vectorData() : null}
          {demo.activeDType === "marketing" ? <MarketingType /> : null}
          {demo.activeDType === "assets" ? <AssetesType /> : null}
          {demo.activeDType === "document" ? <DocumentsTable /> : null}
          {demo.activeDType === "lab" ? <Editor /> : null}
          {demo.activeDType === "sessions" || demo.activeDType === undefined
            ? feedData()
            : null}
        </div>
      </div>
      {active ? (
        <FeedPopup
          active={active}
          setActive={setActive}
          setActiveRow={setActiveRow}
          activeRow={activeRow}
        />
      ) : null}
    </div>
  );
}
