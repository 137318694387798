import React, { useContext } from "react";
import MobileHamburger from "./MobileHamburger";
import { Link } from "react-router-dom";
import { Dropdown, Menu, Select, Space } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import SearchContext from "../../../Context/SearchContext";
import logo from "../../../images/2.png";

export default function LandingHeader() {
    const { user } = useContext(SearchContext);

    const menueItems = () => {
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="landing-logo-no-10 hide-mobile"
          >
            {/* <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Product
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown> */}
            {/* <a
              target="blank"
              style={{ marginRight: "15px" }}
              href={"https://help.telemit.com/"}
            >
              <b className="my-lbutton-10">Guide</b>
            </a> */}
    
            {/* <Dropdown overlay={menu2}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <Space>
                  Use Cases <DownOutlined />
                </Space>
              </a>
            </Dropdown> */}
            <Link to={"/pricing"}>
              <b className="my-lbutton-10">Pricing</b>
            </Link>
    
            <a
              target="blank"
              style={{ margin: "15px" }}
              href={"https://telemit.com"}
            >
              <b className="my-lbutton-10">Company</b>
            </a>
            {user ? null : (
              <Link style={{ marginRight: "15px" }} to={"/signup"}>
                <button className="my-lbutton">Get Started</button>
              </Link>
            )}
            {user ? (
              <Link to={"/dashboard"}>
                <button className="my-lbutton">Dashboard</button>
              </Link>
            ) : (
              <Link to={"/login"}>
                <button className="my-lbutton">Login</button>
              </Link>
            )}
          </motion.div>
        );
      };
      const menu2 = (
        <Menu>
          <Menu.Item>
            <a target="blank" href={"https://guide.telemit.com/game-evaluation"}>
              Gaming
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              target="blank"
              href={"https://guide.telemit.com/telemission/software"}
            >
              Software
            </a>
          </Menu.Item>
        </Menu>
      );
      const items = [
        {
          key: "1",
          label: (
            <a target="blank" href="https://guide.telemit.com/">
              Telemission
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a target="blank" href="https://guide.telemit.com/game-evaluation">
              Playtest
            </a>
          ),
        },
    
        // {
        //   key: "3",
        //   label: (
        //     <a target="blank" href="https://help.telemit.com/product/generate">
        //       Generate
        //     </a>
        //   ),
        // },
        // {
        //   key: "4",
        //   label: (
        //     <a target="blank" href="https://help.telemit.com/product/dendric">
        //       Dendric
        //     </a>
        //   ),
        // },
      ];
      const items2 = [
        {
          key: "11",
          label: "Gaming",
          link: "https://guide.telemit.com/use-case/gaming",
        },
        {
          key: "21",
          label: "Research",
          link: "https://help.telemit.com/use-case/research",
        },
        {
          key: "31",
          label: "Automation",
          link: "https://help.telemit.com/use-case/automation",
        },
        {
          key: "41",
          label: "Creativity",
          link: "https://help.telemit.com/use-case/creativity",
        },
      ];
    
  return (
    <>
      <MobileHamburger menu2={menu2} items={items} />

      <div className="landing-logo-no-u ">
        <Link className="card-logo-top-l" to={"/"}>
          <img className="logo-img-bc" src={logo} alt="" />
        </Link>
        {menueItems()}
      </div>
    </>
  );
}
