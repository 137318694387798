import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { FaArrowRight, FaInfoCircle, FaMinus, FaPlay } from "react-icons/fa";

export default function Sessions({
  feed,
  setAct,
  act,
  setActive,
  setActiveRow,
}) {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState();
  const [callVector, setCallVector] = useState(1);
  const navigate = useNavigate();

  const getSessions = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/feed?temp_id=${feed.temp_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch vectors");
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setSessions(data.feedList);
        }
      })
      .catch((error) => {
        console.error("Error fetching vectors:", error.message);
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 32,
        color: "black",
      }}
      spin
    />
  );
  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Related Sessions</p>
              <div class="dad-joke-prompt">
                <p>
                  There are no related sessions for this instruction. Please
                  head to our console to create another session using this
                  asset.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const DynamicTimeDisplay = (d, id, r) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        <p>
          {hours}:{minutes}:{seconds}
        </p>
        <div
          onClick={() => {
            setActive(r);
            setActiveRow(r);
          }}
          style={{ marginLeft: "15px" }}
          className="icon-circle-br-t"
        >
          <FaPlay />
        </div>
      </div>
    );
  };
  return sessions ? (
    <div className="feeed-t-c-top ">
      <div>
        <div style={{ marginBottom: "10px" }} className="space-between">
          <h4 >Sessions</h4>
          <FaMinus
            className="hover"
            onClick={(v) => {
              setSessions();
            }}
          />
        </div>
        <div style={{ paddingBottom: "0px" }} className="ins-height-sc">
          {sessions && sessions.length === 0 ? emptyAlert() : null}
          <div id="container-vector" className="s3-tabs-c2">
            {sessions &&
              sessions.map((v, vi) => (
                <div
                  id={`id${v.time_length}`}
                  key={vi}
                  className={`vector-r-card ins-watch-c ${
                    v._id === feed._id ? " active-card-ses" : ""
                  }`}
                >
                  <img src={v.thumbnail} alt="" />
                  <p className="max-150h-c">{v.title}</p>
                  <div>{DynamicTimeDisplay(v.time_length, v._id, v)}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      onClick={() => {
        setLoading(true);
        getSessions();
      }}
      className="feeed-t-c-top green-theme-f"
    >
      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <div>
          {" "}
          Sessions <FaArrowRight className="svg-r" />
        </div>
      )}
    </div>
  );
}
