import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";

import Assets from "./Tasks/Assets";


import HeaderCreate from "./Tasks/HeaderCreate";
import { Spin } from "antd";



export default function Start() {
  const {
  
    callApi,
    setCallApi,
    setShowPrice,
  } = useContext(SearchContext);

  const [search1, setSearch1] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);

  const [loadingP, setLoadingP] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoadingP(false);
    }, 500);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return loading ? (
    <Spin />
  ) : (
    <div >
      <div style={{ width: "100%" }}>
        {/* <Header
          windowWidth={windowWidth}
          showCreate={true}
          callApi={callApi}
          setCallApi={setCallApi}
        /> */}
      </div>


        <div >
          <HeaderCreate />
        </div>

    
        <div className="">
          <Assets
            loading2={loading2}
            setLoading2={setLoading2}
            setCallApi={setCallApi}
            callApi={callApi}
          />
        </div>
    
  
    </div>
  );
}
