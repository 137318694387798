import { Button, Input, Modal, Table, Tag } from "antd";
import React, { useState } from "react";

export default function OpenCodeAsset({ row }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Tag
        style={{ marginLeft: "10px" }}
        className={`editor-tag-tab active-tg-wdt show-hover`}
        size="small"
        onClick={() => {
          setShow(true);
        }}
      >
        Open
      </Tag>
      <Modal
        open={show}
        onCancel={() => setShow(false)}
        width={1000}
        className="no-buttons-modal black-modal top-20-modal"
        footer={null}
      >
        <div>
          <p>{row.query}</p>
          <p>{row.text_generation}</p>
        </div>
      </Modal>
    </>
  );
}
