import React, { useContext, useState } from "react";

import { Modal, Button, Input, Slider, message, Spin, Select } from "antd";

import {
  FaFacebook,
  FaGamepad,
  FaReddit,
  FaSteam,
  FaYoutube,
} from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../../Context/SearchContext";
import { AiOutlineNotification } from "react-icons/ai";
const { Option } = Select;
export default function MarketModal() {
  const { user, organization, setOrganization, setShowPrice } =
    useContext(SearchContext);
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();
  const [type, setType] = useState();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const adjustTime = (t) => {
    setGamesData({
      ...gamesData,

      time_length: t,
    });
  };

  const savePlay = (row) => {
    const obj = {
      user_id: user?.user_id,

      status: "market",

      title: gamesData.title,
      instruction: gamesData.instruction,
      channel: gamesData.channel,
      time_length: gamesData.time_length,
    };
    setGamesData();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/market`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setOrganization({
            ...organization,
            callOrg: organization?.callOrg ? organization?.callOrg + 1 : 1,
          });
        } else if (response.msgError === "Insufficient credits") {
          // setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };

  const addForm = () => {
    return (
      <div>
        <div className="no-heigh-div-c editor-container">
          <div
            style={{ paddingTop: "15px",  }}
            className="card21"
          >
            <div className="card21-info">
              <p className="text21-body">Campaign</p>
              <p className="txt-card-21">
                Create livestream campaigns across your most important channels
                that expand your audience
              </p>
            </div>
          </div>
        </div>
        <div className=" form-pf">
          <div className="game-show-p1">
            <div>
              <div>
                <div>
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Title *</span>
                    <input
                      placeholder="Title"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          title: e.target.value,
                        });
                      }}
                      value={gamesData.title}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>

                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Instruction *</span>
                    <Input.TextArea
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      placeholder="Instruction"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          instruction: e.target.value,
                        });
                      }}
                      value={gamesData.instruction}
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </label>

                  <label
                    style={{ margin: "15px 0px" }}
                    for="Platform"
                    class="label-pf"
                  >
                    <span class="title-pf">Channel</span>
                    <select
                      class="input-field-pf"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          channel: e.target.value,
                        });
                      }}
                      style={{ width: "100%" }}
                      value={gamesData.channel}
                    >
                      <option disabled value={""}>
                        Select Channel
                      </option>

                      <>
                        <option value="reddit">
                          <FaReddit /> Reddit
                        </option>
                        <option value="facebook">
                          <FaFacebook /> Facebook
                        </option>
                        <option value="youtube">
                          <FaYoutube /> Youtube
                        </option>
                        <option value="steam">
                          <FaSteam /> Steam
                        </option>
                      </>
                    </select>
                  </label>

                  <div
                    className="together-fields-c"
                    style={{ margin: "15px 0px" }}
                  >
                    <label
                      style={{ width: "100%" }}
                      for="time"
                      class="label-pf"
                    >
                      <span class="title-pf">Time *</span>
                      <div className="biling-m-ci1">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="biling-m-c1 my-sli-play-f"
                        >
                          <Slider
                            min={1}
                            max={5}
                            onChange={adjustTime}
                            value={gamesData.time_length}
                            style={{ width: "100%" }}
                          />
                          <b
                            style={{
                              color:
                                gamesData?.time_length > organization?.balance
                                  ? "red"
                                  : "white",
                            }}
                          >
                            {gamesData.time_length} Hour
                          </b>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {gamesData?.title &&
              gamesData?.title.length &&
              gamesData?.time_length &&
              gamesData?.channel &&
              gamesData?.channel.length &&
              gamesData?.instruction &&
              gamesData?.instruction.length ? (
                <div className="save-btn-pl flex-end">
                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <Button
                      onClick={() => {
                        if (gamesData?.time_length > organization?.balance) {
                          setGamesData();
                          setType();
                          setShowPrice(true);
                        } else {
                          setloading(true);
                          savePlay(gamesData);
                        }
                      }}
                      size="large"
                      className="glow-on-hover"
                      style={{
                        maxWidth: "300px",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="add-peron-main-c">
      <button
        onClick={() => {
          setGamesData({
            ...gamesData,
            step: 1,
            time_length: 1,
            name: "",

            email: "",
            activeCard: undefined,
          });
        }}
        className="my-lbutton gold-btn-c"
      >
        <AiOutlineNotification /> Create
      </button>

      <Modal
        width={700}
        open={gamesData !== undefined}
        onCancel={() => {
          setGamesData();

          setType();
        }}
        className={`no-f-no-h top-20-x  brand-modal `}
      >
        {gamesData ? (
          <div style={{ minHeight: "50vh" }} className="add-percon-mains">
            {addForm()}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
