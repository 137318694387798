import React, { useContext, useEffect, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { FaArrowRight } from "react-icons/fa";
import tImg from "../../images/pexels-pixabay-207924.jpg";

import keyGif from "../landing/component/images/Key.gif";
import potionGif from "../landing/component/images/Potion.gif";
import treasureGif from "../landing/component/images/Treasure0.gif";
import { Col, Row } from "antd";
import "./about.css";
import LandingHeader from "../landing/component/LandingHeader";
function About() {
  return (
    <div
      style={{
        minHeight: "100px",
        height: "100vh",
      }}
      class="blurry-background new-landing-c"
    >
      <LandingHeader />

      <div
        style={{ minHeight: "100px", height: "100vh" }}
        className="control-bf-left"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="landing-info-t"
        >
          <div className="lading-abut-top-c">
            <Row>
              <Col lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <div className="img-ab-sc">
                  <img src={tImg} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <div className="abt-r-txt-c">
                  <h3>Our Story</h3>
                  <p>
                    Gurock Software was founded in 2004 and now has offices in
                    Frankfurt (HQ), Dublin, Austin, and Houston. Our flagship
                    test case management solution, TestRail, is used by more
                    than 100,000 members of development and QA teams to build
                    rock-solid software.
                    <br />
                    <br />
                    Gurock is an Idera, Inc. company. Idera, Inc. is the parent
                    company of global B2B software productivity brands whose
                    solutions enable technical users to do more with less,
                    faster. Idera, Inc. brands span three divisions – Database
                    Tools, Developer Tools, and Test Management Tools – with
                    products that are evangelized by millions of community
                    members worldwide, including some of the world’s largest
                    healthcare, financial services, retail, and technology
                    companies.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="btm-btns-tb-">
            <h3 className="title-2-abt">Our Story</h3>
            <div class="tele-cards landing-tele-card">
              <a target="blank" href="https://guide.telemit.com/snapshots">
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img
                      style={{ width: "23px" }}
                      src={potionGif}
                      alt=""
                      className="card-giv-ce"
                    />
                    Develop
                  </p>
                  <p className="tele-c-desc">
                    Make better decisions by getting a video and visual snapshot
                    of every build and version of your game.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
              <a
                target="blank"
                href="https://guide.telemit.com/game-evaluation"
              >
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img src={keyGif} alt="" className="card-giv-ce" />
                    Playtest
                  </p>
                  <p className="tele-c-desc">
                    Cut your feedback loop to hours with Telemit, helping you
                    build a game players love.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
              <a target="blank" href="https://guide.telemit.com/stream">
                <div class="tele-card tele-blue heigth-tele-card">
                  <p class="tele-tip">
                    <img src={treasureGif} alt="" className="card-giv-ce" />
                    Market
                  </p>
                  <p className="tele-c-desc">
                    Engage more channels and build your community with a
                    continuous livestream of your game.
                  </p>
                  <div className="arrow-c-mcs">
                    <FaArrowRight />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
