import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../Context/SearchContext";
import { TbArrowRight } from "react-icons/tb";
import logo from "../../images/2.png";

export default function Pricing({ hide, logout }) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };
  return (
    <div className="price-cs-sd main-d-onb-c" style={{ width: "100%" }}>
      <div className="logo-login-c">
        <img className="logo-img-bc" src={logo} alt="" />
      </div>
      <div className="pricing-main-c1">
        <div className="pricing-main-c2">
          <h3>Telemit Plans</h3>
          <Row>
            <Col
              lg={{ span: 3 }}
              md={{ span: 3 }}
              xl={{ span: 3 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            ></Col>
            <Col
              lg={{ span: 18 }}
              md={{ span: 18 }}
              xl={{ span: 18 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Standard</h4>
                        <p>
                          Telemit prices for the amount of hours of feedback and
                          marketing usage you request. Pay for the hours you
                          only use.
                        </p>
                      </div>
                      <div>
                        <a
                          href="https://calendly.com/paulw-zhrz/30min"
                          target="blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div className={`my-lbutton black-my-button`}>
                            Request Access
                            <TbArrowRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>$0.09/min</h5> <p>each minute Telemit is used.</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div style={{ marginTop: "20px" }} className="up-bx-mnbcs">
        <span />
        <Button
          onClick={() => {
            updateOrganization("onboard", "thanks");
            setOrganization({ ...organization, onboard: "thanks" });
          }}
          style={{ fontFamily: "inter" }}
          className="black-button"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
