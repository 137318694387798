import { Badge, Button, Modal, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CgLivePhoto } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import FullLoader from "../../../components/FullLoader";
import { MdNotifications } from "react-icons/md";
import ProcessingTable from "./ProcessingTable";
import SearchContext from "../../../Context/SearchContext";

export default function LiveTable() {
  const { user, gamesData, setGamesData, demo, setDemo, count, setCount } =
    useContext(SearchContext);
  const [show, setShow] = useState(false);
  const [rolePlay, setrolePlay] = useState();
  const [live, setLive] = useState("pending");
  const [agent_id, setAgentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("live");
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [callAPI, setCallAPI] = useState(1);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getrolePlay();
    }
  }, [show, pageNo, callAPI]);

  const getrolePlay = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${pageNo}&limit=6&sort_by=${-1}&live=ready&status=demo&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setTimeout(() => {
            setCallAPI(callAPI + 1);
          }, 25000);
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
        }
      });
  };

  const gamingColumns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 200,

      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",

      render: (text, row) => (
        <div className="link-click-cs">
          <a target="blank" href={text}>
            {text}{" "}
          </a>
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
      render: (text, row) => (
        <div style={{ maxHeight: "190px", overflowY: "auto" }}>{text} </div>
      ),
    },

    {
      title: "",
      dataIndex: "stream_url",
      key: "stream_url",

      render: (text, row) => (
        <Link to={`/${row._id}`} target="blank">
          <button
            className="my-lbutton table-lb-btn"
            size="small"
            type="primary"
          >
            Watch <FaArrowRight />
          </button>
        </Link>
      ),
    },
  ];
  return (
    <>
      {count?.live > 0 || count?.processing > 0 ? (
        <Badge color="yellow" dot={true}>
          <MdNotifications
            onClick={() => {
              setShow(true);
            }}
            style={{ cursor: "pointer" }}
            className="notification-icns"
          />
        </Badge>
      ) : (
        <Badge color="yellow" dot={false}>
          <MdNotifications className="notification-icns" />
        </Badge>
      )}
      {/* <button
        className={`my-lbutton fle-x-c ${
          count?.live > 0 ? " green-my-lb " : " red-my-lb "
        }`}
        onClick={() => {
          if (count?.live > 0) {
            setShow(true);
          }
        }}
        style={{ marginLeft: "10px" }}
      >
        <CgLivePhoto className="ani-for-cirlce" /> Live{" "}
        <span className="circle-nmbr">{count?.live}</span>{" "}
        <FaArrowRight className="arrow-right-c" />
      </button> */}
      <Modal
        width={1000}
        open={show}
        onCancel={() => {
          setShow();
          setPageNo(1);
          setTotalPages();
          setrolePlay();
        }}
        className="no-buttons-modal black-modal top-20-x"
      >
        <div className="header-cards-btn">
          <div className="help-text-con"></div>

          <div
            style={{ marginBottom: "20px" }}
            className="filter-box-r flex-center"
          >
            <div
              className={`hover-card-b ${
                type === "live" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setType("live");
              }}
            >
              Live
            </div>
            <div
              className={`hover-card-b ${
                type === "processing" ? " selected-crd" : ""
              } `}
              onClick={() => {
                setType("processing");
              }}
            >
              Processing
            </div>
          </div>
        </div>
        {type === "live" ? (
          <div style={{ minHeight: "300px" }}>
            {loading ? (
              <FullLoader />
            ) : (
              <Table
                className="scrol-tabel-c black-table pagination-white"
                columns={gamingColumns}
                dataSource={rolePlay}
                pagination={{
                  pageSize: 6,
                  current: pageNo,
                  total: totalPages * 6,
                  onChange: (v) => {
                    setrolePlay();
                    setPageNo(v);
                  },
                  size: "small",
                  showSizeChanger: false,
                }}
              />
            )}
          </div>
        ) : (
          <ProcessingTable />
        )}
      </Modal>
    </>
  );
}
