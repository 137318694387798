import React, { useContext, useEffect } from "react";
import SearchContext from "../../../../../Context/SearchContext";
import { Button, Tooltip, message } from "antd";

import AddBlock from "../../left/AddBlock";
import GenerateBehaviour from "./GenerateBehaviour";
import { FaArrowRight } from "react-icons/fa";
export default function ActiveConnections() {
  const { connectors, setConnectors, user } = useContext(SearchContext);
  // useEffect(() => {
  //   if (connectors === undefined) {
  //     getlistConnections();
  //   }
  // }, []);
  const getlistConnections = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list/connect?page=1&limit=5&sort_by=${-1}&section=private&user_id=${
        user && user.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setConnectors(data.listList);
        }
      });
  };
  const deleteAutomateData = (data) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    // Make a DELETE request to your API here
    fetch(`https://embed.axv.ai/api/list/${data._id}`, requestOptions)
      .then((response) => response.json())
      .then((d) => {
        if (d.isSuccess) {
          let all = connectors.filter((f) => f._id !== data._id);
          setConnectors(all);
        } else {
          message.error("Failed to remove data");
        }
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        message.error("An error occurred while deleting data");
      });
  };
  return (
    <div className="active-conn-c">
      <div />
      <div className="left-area-active-conn">

        <AddBlock type={"Assets"} />
      </div>

   
    </div>
  );
}
