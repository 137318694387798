import React, { useContext } from "react";
import SearchContext from "../Context/SearchContext";
import { Modal } from "antd";
import Billing from "../pages/billing/Billing";


export default function PricingModal() {
  const { showPrice, setShowPrice } = useContext(SearchContext);
  return (
    <Modal
      open={showPrice}
      onCancel={() => {
        setShowPrice();
      }}
      width={800}
      className="no-buttons-modal black-modal top-20-modal"
    >
        <Billing />
    </Modal>
  );
}
