import React from "react";
export default function FullLoader() {
  return (
<div class="fl-container">
  <div class="fl-block"></div>
  <div class="fl-block"></div>
  <div class="fl-block"></div>
  <div class="fl-block"></div>
</div>
  );
}
