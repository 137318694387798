import { Button } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../Context/SearchContext";
import logo from "../../images/2.png";

export default function Intro({ logout }) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const [agree, isAgree] = useState(false);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  return (
    <div className="main-d-onb-c">
        <div className="logo-login-c">
          <img className="logo-img-bc" src={logo} alt="" />
        </div>
      <div className="intro-2nd-dc">
        <h1>Welcome To Telemit </h1>
        <p>
          We're thrilled to have you on board. Our platform is designed to
          accelerate your game development process, providing you with rapid
          feedback and insights to help you create games that resonate with
          players.
        </p>
      </div>
      <div className="onb-m-bot">
        <div className="up-bx-mnbcs">
          <Button
            onClick={() => {
              logout();
            }}
         
          >
            Sign Out
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "60px" }}>
              <Button
                onClick={() => {
                  updateOrganization("onboard", "verification");
                  setOrganization({ ...organization, onboard: "verification" });
                }}
                className="black-button"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
