import React, { useContext, useEffect, useState } from "react";

import { Select } from "antd";
import SearchContext from "../../../Context/SearchContext";
import { IoMdGitMerge } from "react-icons/io";
const { Option } = Select;

export default function Blocks() {
  const { demo, setDemo, gamesData, setGamesData } = useContext(SearchContext);
  const [blocks, setBlocks] = useState();

  useEffect(() => {
    if (demo.data === undefined) {
      getBlocks();
    }
  }, []);

  const getBlocks = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/block?page=1&limit=1000&sort_by=${-1}&block_type=version&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.blockList) {
          setBlocks(data.blockList);
          // setTotalPages(data.totalPages);
        }
      });
  };

  return blocks ? (
    <>
      <Select
        value={demo.activeBlock ? demo.activeBlock : ""}
        onChange={(e) => {
          setGamesData({
            ...gamesData,
            memoryData: undefined,
            pageNoMemo: 1,
            totalPagesMemo: undefined,
          });
          setDemo({ ...demo, activeBlock: e });
        }}
        className="transparentinput"
        style={{ height: "34px", minWidth: "120px" }}
      >
        <Option value={""}><IoMdGitMerge className="vid-top-dif-cs" />All</Option>
        {blocks &&
          blocks.map((d, di) => {
            return (
              <Option key={di} value={d.value}>
                <IoMdGitMerge className="vid-top-dif-cs" />
                {d.value}
              </Option>
            );
          })}
      </Select>
    </>
  ) : null;
}
