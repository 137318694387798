import React, { useContext, useEffect, useState } from "react";

import {
  Modal,
  Button,
  Input,
  Slider,
  message,
  Spin,
  Table,
  Popconfirm,
} from "antd";

import { FaPlus } from "react-icons/fa";
import Paragraph from "antd/es/typography/Paragraph";
import SearchContext from "../Context/SearchContext";

export default function FeedPlusModal() {
  const { user, demo, setDemo, plusFeed, setPlusFeed, organization } =
    useContext(SearchContext);
  const [loading, setloading] = useState(false);

  const [type, setType] = useState();
  const [isChange, setIsChange] = useState(false);

  const savePlay = (row) => {
    const obj = {
      user_id: user?.user_id,

      status: "pending",
      feed_type: "game",
      media_type: plusFeed.media_type,
      title: plusFeed.title,
      device: plusFeed.device,
      recording_type: plusFeed.recording_type,
      description: plusFeed.description,
      link: plusFeed.link,
      version_id: plusFeed.version_id,
      steam_key: plusFeed.steam_key,
      platform: plusFeed.platform,
      time_length: plusFeed.time_length,
    };
    setPlusFeed();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/core`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          setDemo({
            ...demo,
            callFeedGame: demo.callFeedGame ? demo.callFeedGame + 1 : 1,
          });
          message.success("Added");
        } else if (response.msgError === "Insufficient credits") {
          // setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };

  const addForm = () => {
    return (
      <div className=" form-pf">
        <div className="game-show-p1">
          <div>
            <div>
              <div>
                {plusFeed?.activeCard ? (
                  <div
                    style={{ padding: "5px" }}
                    class="tele-cards tele-more-space-c gold-card-s"
                  >
                    <div>
                      <div class="tele-card tele-blue heigth-tele-card full-w-tele">
                        <img
                          className="card-img-s1"
                          src={plusFeed?.activeCard?.thumbnail}
                          alt=""
                        />
                        <div className="tele-m-c-f1">
                          <div>
                            <p class="tele-tip">
                              {plusFeed?.activeCard?.title}
                            </p>
                            <Paragraph
                              ellipsis={{
                                rows: 4,
                                // expandable: true,
                                symbol: "see more",
                              }}
                              style={{ width: "100%" }}
                              className="desc-p-crdjjkl"
                            >
                              {plusFeed?.activeCard?.description}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Name *</span>
                    <input
                      placeholder="Name"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          title: e.target.value,
                        });
                      }}
                      value={plusFeed.title}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}
                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="link"
                    class="label-pf"
                  >
                    <span class="title-pf">Link *</span>
                    <input
                      placeholder="Link"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          link: e.target.value,
                        });
                      }}
                      value={plusFeed.link}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}

                {plusFeed?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="Platform"
                    class="label-pf"
                  >
                    <span class="title-pf">Platform</span>
                    <select
                      class="input-field-pf"
                      onChange={(e) => {
                        setPlusFeed({
                          ...plusFeed,

                          platform: e.target.value,
                          steam_key: undefined,
                        });
                      }}
                      value={plusFeed.platform}
                    >
                      <option disabled value={undefined}>
                        Select Platform
                      </option>

                      <>
                        <option value="chrome">Chrome</option>
                        <option value="epic_Store">Epic Store</option>
                        <option value="steam">Steam</option>
                        <option value="none">None</option>
                      </>
                    </select>
                  </label>
                )}

                <label
                  style={{ margin: "15px 0px" }}
                  for="name"
                  class="label-pf"
                >
                  <span class="title-pf">Description </span>
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    placeholder="Description"
                    onChange={(e) => {
                      setPlusFeed({
                        ...plusFeed,
                        description: e.target.value,
                      });
                    }}
                    value={plusFeed.description}
                    style={{ width: "100%" }}
                    size="large"
                  />
                </label>
              </div>
            </div>
            {plusFeed?.title &&
            plusFeed?.title.length &&
            plusFeed?.link &&
            plusFeed?.link.length &&
            plusFeed?.platform &&
            plusFeed?.platform.length ? (
              <div className="save-btn-pl flex-end">
                {loading ? (
                  <Spin />
                ) : (
                  <Button
                    onClick={() => {
                      // setloading(true);
                      // setPlusFeed({
                      //   ...plusFeed,
                      //   showPlay: undefined,
                      // });
                      setloading(true);
                      savePlay(plusFeed);
                    }}
                    size="large"
                    className="glow-on-hover"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    Start
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const feedTable = () => {
    return (
      <Table
        className="scrol-tabel-c black-table pagination-white"
        columns={gamingColumns}
        dataSource={demo.data}
        pagination={{
          // pageSize: 6,
          // current: pageNo,
          // total: totalPages * 6,
          // onChange: (v) => {
          //   setrolePlay();
          //   setPageNo(v);
          // },
          size: "small",
          showSizeChanger: false,
        }}
        style={{ marginTop: "30px" }}
      />
    );
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = demo.data.map((m) => (m._id === row._id ? row : m));

    setDemo({ ...demo, data: allRow });
  };
  const gamingColumns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Name"
          onChange={(e) => {
            changeField("title", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ title: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Link"
          onChange={(e) => {
            changeField("link", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ link: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",

      render: (text, row) => (
        <select
          class="hidden-textarea"
          onChange={(e) => {
            changeField("platform", e.target.value, row);
            saveChanges({ platform: text }, row._id , true)

          }}

          value={text}
          style={{padding:"6px"}}
        >
          <>
            <option value="chrome">Chrome</option>
            <option value="epic_Store">Epic Store</option>
            <option value="steam">Steam</option>
            <option value="none">None</option>
          </>
        </select>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteFile(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" className="red-button">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = demo.data.filter((t) => t._id !== id);

          setDemo({ ...demo, data: filtereted });
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return (
    <>
      <Button
        onClick={() => {
          setPlusFeed({
            ...plusFeed,
            creeate: true,
            time_length: 1,
            name: "",
            title:"",
            description:"",
            link:"",
            platform:"chrome",
            email: "",
            activeCard: undefined,
          });
        }}
        className="grey-button"
      >
        <FaPlus />
      </Button>

      <Modal
        width={800}
        open={plusFeed !== undefined && organization?.success}
        onCancel={() => {
          if (plusFeed.isFirst) {
          } else {
            setPlusFeed();

            setType();
          }
        }}
        className={`no-f-no-h top-20-x  brand-modal `}
      >
        {plusFeed ? (
          <div>
            {plusFeed.isFirst ? null : (
              <div className="tabs-c-feed-c">
                <Button
                  onClick={() => {
                    setPlusFeed({
                      ...plusFeed,
                      creeate: true,
                    });
                  }}
                  className={plusFeed.creeate ? " green-button" : ""}
                  type={plusFeed.creeate ? "link" : "defaul"}
                >
                  Create
                </Button>
                <Button
                  onClick={() => {
                    setPlusFeed({
                      ...plusFeed,
                      creeate: false,
                    });
                  }}
                  className={!plusFeed.creeate ? " green-button" : ""}
                  type={!plusFeed.creeate ? "link" : "defaul"}
                >
                  Games
                </Button>
              </div>
            )}
            <div style={{ minHeight: "50vh" }} className="add-percon-mains">
              {plusFeed.isFirst ? (
                <div
                  style={{ height: "auto" }}
                  className="no-heigh-div-c editor-container"
                >
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #444444, #545454)",
                    }}
                    className="card21"
                  >
                    <div style={{ paddingTop: "15px" }} className="card21-info">
                      <p className="text21-body">Connect Your Game</p>
                      <p className="txt-card-21">
                        Add your game to the Telemit platform to get the most
                        from our services.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {plusFeed.creeate ? addForm() : feedTable()}
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
