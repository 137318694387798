import React, { useContext, useState } from "react";

import {
  Alert,
  Button,
  Input,
  Spin,
  Upload,
  message,
  notification,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  MdInsights,
  MdOutlineCreate,
  MdOutlineImageSearch,
} from "react-icons/md";
import SearchContext from "../../Context/SearchContext";
import logo from "../../images/logo-s.png";
import axios from "axios";
const { Dragger } = Upload;
let all = [];
export default function UploadP({logout}) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const [UploadedFile, setUplaoded] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uImg, setImg] = useState();
  const [avatar, setAvatar] = useState();
  const [username, setUsername] = useState("");
  const [textField, setTextField] = useState(""); // State to store text input

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };
  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          {imageUrl ? (
            <img
              width={100}
              className="img-prev-ibc mx-auto "
              src={imageUrl}
              alt=""
            />
          ) : (
            "ssss"
          )}
          {UploadedFile.length ? (
            <Button type="link" onClick={removeFile}>
              Change
            </Button>
          ) : null}
        </>
      );
    } else {
      return (
        <div>
          <span>Upload An Avatar</span>{" "}
          {/* <span className="optional-text">(Optional)</span> */}
        </div>
      );
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  const saveFile = (f, i, id) => {
    const formData = new FormData();
    formData.append("file", f.originFileObj);

    formData.append("purpose", "profile-image");
    formData.append("media_type", "profile-image");
    formData.append("user_id", user && user.user_id);

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);

        setFileList([]);

        if (data && data.isSuccess) {
          console.log(data.data);
          setImg(data.data);
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "Please upload another image. Unfortunately, this image didn't meet our NSFW guidelines ",
          });
        }
      });
  };

  const updateUser = () => {
    const obj = {
      username,
      profile_picture:
        uImg && uImg.file_url ? uImg.file_url : avatar ? avatar[0] : undefined,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/${user.id}/patch`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          user.name = response.patchedData.name;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        } else {
          message.error("somehing went wrong");
        }
      });
  };



  const handleSendClick = async () => {
    try {
      setLoading(true); // Show loader when sending the request
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/image/avatar",
        {
          query: textField,
          user_id: user && user.user_id,
          username: user && user.username,
        }
      );
      console.log(response);
      // Handle the response here if needed
      setAvatar(response.data.images);
      // Hide the loader
      setLoading(false);
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
      // Hide the loader on error
      setLoading(false);
    }
  };

  return (
    <div className="onb-main-bx">
      <div>
        <div className="onb-main-hv">
          <img src={logo} alt="" style={{ width: "40px", height: "40px" }} />
        </div>
        <div className="upload-bx-cs-nm col-lg-10 col-xl-7 mx-auto">
          <div className="icons-bx-main-ne">
            <div
              className="col mb-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="avatar-c-bx">
                {avatar &&
                  avatar.map((a, ai) => {
                    return <img src={a} alt="" />;
                  })}
              </div>

              <div className="avatar-c-bx" style={{ marginBottom: "20px" }}>
                <span style={{ marginRight: "10px" }}>Username</span>
                <input
                  id="inputPassword"
                  type="text"
                  placeholder="username"
                  onChange={(e) =>
                    setUsername(e.target.value.replace(/\s/g, ""))
                  }
                  value={username}
                  required=""
                  className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                />
              </div>

              {/* <div className="avatar-c-bx">
                <span>Avatar</span>

                <input
                style={{marginLeft:"35px"}}
                  placeholder="Start Your Idea Here"
                  value={textField}
                  className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                  onChange={(e) => setTextField(e.target.value)}
                />
              </div> */}

              {/* <div className="create-avatar-bx">
                {loading ? (
                  <div>
                    <Spin style={{ marginRight: "8px" }} />
                  
                  </div>
                ) : (
                  <Button onClick={() => handleSendClick()} disabled={loading}>
                    Create Avatar
                  </Button>
                )}
              </div>
              <div className="or-bx-vv">Or</div> */}

              {uImg ? (
                <div>
                  <img width={100} src={uImg.file_url} alt="" />
                </div>
              ) : (
                <Dragger
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={handleBeforeUpload}
                  {...props}
                >
                  {renderFileName()}
                </Dragger>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="onb-m-bot">
        <div className="up-bx-mnbcs">
          <Button
            onClick={() => {
              logout();
            }}
            type="primary"
          >
            Sign Out
          </Button>
          {UploadedFile.length ? (
            loading ? (
              <Spin />
            ) : (
              <span>
                <Button
                  onClick={() => {
                    setLoading(true);
                    all = [];

                    let id = uuidv4();
                    for (let i = 0; i < UploadedFile.length; i++) {
                      saveFile(UploadedFile[i], i, id);
                    }
                  }}
                  type="primary"
                >
                  Save Image
                </Button>
              </span>
            )
          ) : null}
          {UploadedFile.length === 0 && username.length > 2 ? (
            <Button
              onClick={() => {
                updateUser();
                updateOrganization("onboard", "credit");
                setOrganization({ ...organization, onboard: "credit" });
              }}
            >
              Next
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
