import { Col, message, Row, Tabs, Tooltip, Upload, Button, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import EditPassword from "../components/EditPassword";
import "../account.css";
import SearchContext from "../../../Context/SearchContext";

export default function MyAccount() {
  const { user, setUser, organization } = useContext(SearchContext);
  const [userName, setuserName] = useState();

  const updateUser = () => {
    const obj = {
      name: userName,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/${user.id}/patch`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          user.name = response.patchedData.name;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        } else {
          message.error("somehing went wrong");
        }
        setuserName();
      });
  };

  return (
    <div className="container">
      <div className="acc-topc form-pf">
        <div className="account-page-c">
          {user ? (
            <div className="tags-date-area right-area-det-m">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <label for="Username" class="label-pf">
                        <span class="title-pf">Username</span>
                        <Input
                          type="text"
                          placeholder="User Name"
                          value={userName ? userName : user?.name}
                          onChange={(e) => {
                            setuserName(e.target.value);
                          }}
                          style={{ width: "300px" }}
                          class="input-field-pf"
                        />
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <label for="Email" class="label-pf">
                        <span class="title-pf">Email</span>
                        <div>
                          <Input
                            disabled
                            type="text"
                            value={user?.email}
                            class="input-field-pf"
                            style={{ width: "300px" }}
                          />
                          <Tooltip
                            className="account float-right mt-1"
                            color={
                              organization?.color_code &&
                              organization.color_code !== "null"
                                ? organization.color_code
                                : "black"
                            }
                            placement="right"
                            title="To change the email on your account, please contact us."
                          >
                            <FaQuestionCircle
                              style={{ marginLeft: "5px", marginTop: "-30px" }}
                            />
                          </Tooltip>
                        </div>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <label for="Password" class="label-pf">
                        <span class="title-pf">Password</span>
                        <div>
                          <Input
                            type="text"
                            disabled
                            placeholder="User Name"
                            class="input-field-pf"
                            value="**********"
                            style={{ width: "300px" }}
                          />
                        </div>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ paddingTop: "20px" }} className="flex-end">
                      {userName ? (
                        <div onClick={updateUser} className="hover-card-b">
                          Update
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
