import { Modal } from "antd";
import React, { useContext, useEffect } from "react";
import SearchContext from "../../Context/SearchContext";
import UploadP from "./Upload";
import Pricing from "./Pricing";
import Term from "./Term";
import Verification from "./verification";
import Intro from "./Intro";
import Thanks from "./Thanks";

export default function Onboard() {
  const { user, setUser, organization, setOrganization, demo } =
    useContext(SearchContext);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      // setUser(u);
      getOrganization(u);
    }
  }, [user , organization?.callOrg]);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  const updatePerson = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/people/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const oldP = JSON.parse(window.localStorage.getItem("persons"));
          if (oldP) {
            let all = oldP.filter((f) => f._id !== id);
            window.localStorage.setItem("persons", JSON.stringify(all));
          }
          window.location.reload();
          // window.localStorage.removeItem("person")
       
        }
      });
  };
  const logout = () => {
    window.localStorage.removeItem("user");

    setUser();
    window.location.reload();
  };
  const logoutFun = () => {
    // updatePerson({ seat_status: "watcher", status: "left" }, demo.person?._id);
    logout()
  };
  return (
    <Modal
      width={800}
      
      className="no-buttons-modal no-close-model black-modal pricing-modal"
      open={
        (organization && organization.onboard === "intro") ||
        (organization && organization.onboard === "thanks") ||
        (organization && organization.onboard === "verification") ||
        (organization && organization.onboard === "pending") ||
        (organization && organization.onboard === "pricing") ||
        (organization && organization.onboard === "upload")
      }
    >
      {organization ? (
        <div className="onboard-modal-csv">
          {organization.onboard === "intro" ? (
            <Intro logout={logoutFun} />
          ) : null}
          {organization.onboard === "thanks" ? (
            <Thanks logout={logoutFun} />
          ) : null}
          {organization.onboard === "verification" ? (
            <Verification logout={logoutFun} />
          ) : null}
          {organization.onboard === "pending" ? (
            <Term logout={logoutFun} />
          ) : null}
          {organization.onboard === "upload" ? (
            <UploadP logout={logoutFun} />
          ) : null}
          {organization.onboard === "pricing" ? (
            <Pricing logout={logoutFun} />
          ) : null}
        </div>
      ) : null}
    </Modal>
  );
}
