import {
  Button,
  Input,
  message,
  Modal,
  Upload,
  Collapse,
  Select,
  Spin,
  Popover,
  notification,
} from "antd";
import React, { useContext, useRef, useState } from "react";
import { FaArrowLeft, FaMinus } from "react-icons/fa";
// import VideoAdd30 from "../../../components/VideoAdd30";
import { v4 as uuidv4 } from "uuid";

import { BsArrowDownShort, BsFillCloudUploadFill } from "react-icons/bs";

import ReactPlayer from "react-player";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../../Context/SearchContext";
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

let all = [];
export default function UploadVideo({ isHome, type2, setdAdd }) {
  const playerRef = useRef();

  const {
    setFormType,
    setassist,
    assist,
    organization,
    setOrganization,
    pActive,
    right,
    setRight,
    user,
    images,
    setImages,
    setShowPrice,
    videosBeingUploaded,
    setVideosBeingUploaded,
  } = useContext(SearchContext);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [mData, setMData] = useState("");
  const [videoErr, setVideoErr] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);

  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("video_duration", lengthS);
    formData.append("purpose", "video");
    formData.append("index", "pending");
    formData.append("meta_data", mData);
    // formData.append("project_id", window.location.pathname.slice(7));
    formData.append("user_id", user ? user.user_id : "map");

    setDisplay(false);
    setLoading(false);
    setdAdd();
    const vidId = Math.random();
    console.log(UploadedFile)
    const videoName = UploadedFile.length ? UploadedFile[0].name : "";
    setVideosBeingUploaded((prevState) => {
      return [...prevState, { id: vidId, name: videoName, isLoading: true }];
    });
    removeFile()
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        setVideosBeingUploaded((prevState) => {
          const newState = [...prevState];

          const idx = newState.findIndex((el) => el.id === vidId);
          if (idx > -1) {
            newState[idx].isLoading = false;
            newState[idx].success = response.status === 200;
          }

          return newState;
        });
        return response.json();
      })
      .then((data) => {
        if (data.message === "Insufficient compute available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data.message === "video_duration is required") {
          message.success("Upload Failed. Please try again.");
        }

        if (data && data.isSuccess) {
          if (i + 1 === UploadedFile.length) {
          }
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          {imageUrl && type2 === "image" ? (
            <img className="img-prev-ibc" src={imageUrl} alt="" />
          ) : imageUrl && type2 === "video" ? (
            <div className="vd-nm-pr img-prev-ibc">
              <ReactPlayer url={imageUrl} controls={false} />
            </div>
          ) : (
            <span>{file.name}</span>
          )}
        </>
      );
    } else {
      return <span>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
    setUplaoded([]);
  };

  function handleRemove() {
    setImageUrl(null);
    message.success("Image removed successfully!");
  }

  return (
    <div>
      <div className={`upload-pop-mbc `}>
        {/* {display30 ? <VideoAdd30 setDisplay30={(v) => setDisplay30(v)} /> : null} */}
        {/* {renderType === "assets" ? ( */}
        {right.upOpen ? (
          <div className="close-as-bn">
            <MdClose onClick={() => setRight({ ...right, upOpen: false })} />
          </div>
        ) : null}
        {/* ) : null} */}
        {isHome ? (
          <div className="ty-nw-bx-tnx">
            <b>Try Now</b>
            <BsArrowDownShort className="arrow-dwn" />
          </div>
        ) : null}

        <div className="upload-bx-mnn01">
          <div className="upload-bx-mnn">
            <div
              className="vid-ad-vx tag-grou-lake-c"
              style={{ gridColumn: "2 / span 1", marginBottom: "1px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {user && user.id ? (
                  <Dragger
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    {...props}
                  >
                    {renderFileName()}
                  </Dragger>
                ) : (
                  <div
                    onClick={() => {
                      setFormType("signup");
                    }}
                    className="no-login-up"
                  >
                    Drag or click to upload
                  </div>
                )}
                {/* {videoErr && type === "video" ? (
                  <h6>Video is longer than 3 minutes</h6>
                ) : null} */}
              </div>
              <div className="up-bx-mnbcs">
                {UploadedFile.length ? (
                  <Button size="small" type="default" onClick={removeFile}>
                    Change
                  </Button>
                ) : null}
                {UploadedFile.length ? (
                  loading ? (
                    <Spin />
                  ) : (
                    <Button
                      style={{ marginLeft: "10px" }}
                      className={`editor-tag-tab  active-tg-wdt`}
                      type="primary"
                      size="small"
                      onClick={() => {
                        if (
                          organization &&
                          organization.subscription === "Proooo"
                        ) {
                          setdAdd();
                          setShowPrice(true);
                        } else {
                          setLoading(true);
                          all = [];

                          let id = uuidv4();
                          for (let i = 0; i < UploadedFile.length; i++) {
                            saveVideo(UploadedFile[i], i, id);
                          }
                        }
                      }}
                    >
                      Attach
                    </Button>
                  )
                ) : null}
              </div>
            </div>

            {UploadedFile.length ? (
              <div className="pic-2vid0c">
                <ReactPlayer
                  url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                  className=""
                  controls
                  playing
                  muted
                  ref={playerRef}
                  onProgress={(e) => getVideoLength(e.loadedSeconds)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
