import { Button, Modal, Popover } from "antd";
import React, { useContext, useRef, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
//import _ from 'lodash';
import SearchContext from "../../../../../Context/SearchContext";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as geolib from "geolib";

export default function MapD({ row }) {
  const {
    user,
    setUser,
    setOrganization,
    resetAll,
    videosFeed,
    setVideosFeed,
  } = useContext(SearchContext);
  const [mapData, setMapData] = useState();
  const mapRef = useRef();
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/dark-v11");
  const [viewport, setViewport] = useState({
    // longitude: -119.417931,
    // latitude: 36.778259,
    zoom: 0,
    minZoom: 0,
    maxZoom: 15,
    pitch: 40.5,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);

  const getVideos2 = (r) => {
    let d;
    if (r.mapData && r.mapData[0]) {
      d = r.mapData[0].category;
    } else {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/insight?category=${d}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setMapData(data.insightList);
          adjustZoom(data.insightList);
        }
      });
  };
  const adjustZoom = (data) => {
    let center;

    if (center === undefined && data && data.length) {
      center = geolib.getCenter(data.filter((f) => f.longitude && f.latitude));
    }
    // console.log(center , data)

    if (center) {
      console.log(mapRef.current);
      mapRef.current?.flyTo({
        center: [center.longitude, center.latitude],
        zoom: 1,
        duration: 4000,
      });
      setViewport({
        ...viewport,
        zoom: 3,
        latitude: center.latitude,
        longitude: center.longitude,
      });
    }
  };
  return (
    <div>
      <Button onClick={() => getVideos2(row)} size="large">
        <FaMapMarkerAlt className="r-atc-incs" />
      </Button>
      <Modal
        open={mapData !== undefined}
        onCancel={() => setMapData()}
        width={1000}
        className="no-buttons-modal black-modal top-20-modal"
      >
        {mapData ? (
          <Map
            // {...viewport}
            mapboxAccessToken="pk.eyJ1Ijoib2xtYWkiLCJhIjoiY2xmcXl3MHVpMDNidjNzcG85aWJ5M2w3NCJ9.PKf8J7x_Pu7Psbuh-n0y8w"
            onViewportChange={(newViewport) => {
              setViewport(newViewport);
            }}
            initialViewState={viewport}
            ref={mapRef}
            mapStyle={mapStyle}
            style={{ width: "100%", height: "80vh" }} // Set a height for the map
          >
            {mapData.map((marker) => (
              <Marker
                key={marker._id}
                latitude={marker.latitude}
                longitude={marker.longitude}
              >
                <Popover
                  title={marker.title}
                  content={
                    <div>
                      <p>{marker.description}</p>
                      {/* Add more details as needed */}
                    </div>
                  }
                >
                  <div
                    className="marker"
                    // onMouseOver={() => setSelectedMarker(marker)}
                  >
                    📍
                  </div>
                </Popover>
              </Marker>
            ))}

            {/* {selectedMarker && (
              <Popup
                latitude={selectedMarker.latitude}
                longitude={selectedMarker.longitude}
                onClose={() => setSelectedMarker(null)}
              ></Popup>
            )} */}
          </Map>
        ) : null}
      </Modal>
    </div>
  );
}
