import { Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";

import logo from "../../../images/2.png";
import SearchContext from "../../../Context/SearchContext";
export default function OnboardSuccessModal() {
  const { user, setUser, organization, setOrganization, demo } =
    useContext(SearchContext);
  const [callOrg, setCallOrg] = useState(1);


  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u && organization?.onboard === "tour") {

      getOrganization(u);
    }
  }, [user, callOrg, organization?.onboard]);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          if (
            response.organization &&
            response.organization.success === false
          ) {
            setTimeout(() => {
              setCallOrg(callOrg + 1);
            }, 10000);
          }
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  const logout = () => {
    window.localStorage.removeItem("user");

    setUser();

    window.location.reload();
  };
  return (
    <Modal
      width={600}
      className="no-buttons-modal no-close-model black-modal pricing-modal"
      open={
        organization &&
        organization.success === false &&
        organization.onboard === "tour"
      }
    >
      {organization ? (
        <div style={{ minHeight: "auto" }} className="onboard-modal-csv">
          <div
            style={{ justifyContent: "flex-start", height: "auto" }}
            className="main-d-onb-c"
          >
            <div className="logo-login-c">
              <img className="logo-img-bc" src={logo} alt="" />
            </div>
            <div className="intro-2nd-dc">
              <h2> Onboard Your Game </h2>
              <p>
                An onboarding specialist will reach out to you shortly to
                onboard your first game and explain how Telemit works. Thank you
              </p>
            </div>
            <div className="up-bx-mnbcs">
              <div />

              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "60px" }}>
                  <Button
                    onClick={() => {
                      logout();
                    }}
                    className="black-button"
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}
